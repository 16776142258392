import {Modal} from "react-bootstrap";
import PitchButton from "../buttons/PitchButton";

export default function InfoModal({message, Body, cancelCallback, acceptCallback}) {

    return (
        <Modal
            show={message!==null}
            onHide={cancelCallback}
            backdrop="static"
            keyboard={false}
        >
            {message && !Body && (
                <>
                <Modal.Header className={message.variant === "warning" ? "container-secondary-1-0" : "container-complement-0"} closeButton>
                    <Modal.Title>{message.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message.body}
                </Modal.Body>
                <Modal.Footer>
                    {message.cancelButtonText && (
                        <PitchButton variant="secondary" onClick={cancelCallback}>
                            {message.cancelButtonText}
                        </PitchButton>
                    )}
                    <PitchButton variant={message.variant === "warning" ? "warning" : "secondary"} onClick={acceptCallback}>
                        {message.buttonText}
                    </PitchButton>
                </Modal.Footer>
                </>
            )}
            {!message && Body && (<Body />)}
        </Modal>
    );
}