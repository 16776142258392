import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isOrgAccountActive } from "../../api/localstorage_api";
import { useAppContext } from "../../libs/contextLib";
import { isOrganisationSignUpComplete, useOrganisation, usePageTitle, useUser } from "../../libs/hooksLib";
import PlaceholderPage from "../layout/PlaceholderPage";

export default function OrgAuthenticatedRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();
  const {user} = useUser();
  const {organisation} = useOrganisation();

  usePageTitle(title);
  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  if(isAuthenticated){
    if(isOrgAccountActive()){
      if(isOrganisationSignUpComplete(isAuthenticated, user, organisation)){
        return children;
      }else{
        return <Navigate replace to={`/org_signup?redirect=${pathname}${search}`} />
      }
    }else{
      return (
        <PlaceholderPage>
          <h1>Sorry! You do not have permission to view this page.</h1>
        </PlaceholderPage>);
    }
  }else{
    <Navigate replace to={`/org_signup?redirect=${pathname}${search}`} />
  }
}