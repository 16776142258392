import LoadingImage from "./LoadingImage";
import PanelContainer from "./PanelContainer";
import VerticalCenterContainer from "./VerticalCenterContainer";

export default function LoadingContainer({text}){
    return(
        <VerticalCenterContainer>
            <PanelContainer size="small" className="text-center">
                <LoadingImage text={text}/>
            </PanelContainer>
        </VerticalCenterContainer>
    )
}