import React, { lazy } from "react";
import { Routes } from "react-router-dom";
import HomeRoute from "./components/routes/HomeRoute";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/routes/UnauthenticatedRoute";
import CandidateMixedRoute from "./components/routes/CandidateMixedRoute";
import SignupRoute from "./components/routes/SignupRoute";
import OrgSignupRoute from "./components/routes/OrgSignupRoute";
import { Link } from "./libs/shared/model";
import { statusFilterTypes } from "./libs/shared/enums";
import OrgAuthenticatedRoute from "./components/routes/OrgAuthenticatedRoute";
import CandidateAuthenticatedRoute from "./components/routes/CandidateAuthenticatedRoute";
import OrgMixedRoute from "./components/routes/OrgMixedRoute";
import { Suspense } from "react";
import LoadingContainer from "./components/layout/LoadingContainer";
import { Navigate, Route } from "react-router";
import BasicRoute from "./components/routes/BasicRoute";

const Home = lazy(() => import ('./containers/home/Home'));
const HomeLandingPage = lazy(() => import ('./containers/home/HomeLandingPage'));
const LoginPathChoice = lazy(() => import("./containers/sign-in/LoginPathChoice"));
const Login = lazy(() => import("./containers/sign-in/Login"));
const SignUpWizard = lazy(() => import("./containers/sign-up-wizard/SignUpWizard"));
const OrganisationSignUpWizard = lazy(() => import("./containers/sign-up-wizard/OrganisationSignUpWizard"));
const ResetPasswordWizard = lazy(() => import("./containers/sign-in/ResetPasswordWizard"));
const Links = lazy(() => import("./containers/links/Links"));
const LinksConvert = lazy(() => import("./containers/links/LinksConvert"));
const InviteContainer = lazy(() => import("./containers/invites/InviteContainer"));
const InviteAnalytics = lazy(() => import("./containers/invites/InviteAnalytics"));
const ApplicationWizard = lazy(() => import("./containers/applications/ApplicationWizard"));
const ApplicationContainer = lazy(() => import("./containers/applications/ApplicationContainer"));
const JobsSharedWithMe = lazy(() => import("./containers/jobs/JobsSharedWithMe"));
const CandidateFeed = lazy(() => import("./containers/jobs/CandidateFeed"));
const JobWizard = lazy(() => import("./containers/jobs/JobWizard"));
const JobContainer = lazy(() => import("./containers/jobs/JobContainer"));
const CandidateContainer = lazy(() => import("./containers/candidate/CandidateContainer"));
const JobAnalytics = lazy(() => import("./containers/jobs/JobAnalytics"));
const CandidateSettings = lazy(() => import("./containers/settings/candidate/CandidateSettings"));
const OrganisationSettings = lazy(() => import("./containers/settings/organisation/OrganisationSettings"));
const Products = lazy(() => import("./components/products/Products"));
const LegalContainer = lazy(() => import("./containers/legal/LegalContainer"));
const PrivacyContainer = lazy(() => import("./containers/legal/PrivacyContainer"));
const PricingContainer = lazy(() => import("./containers/marketing/PricingContainer"));

export default function PitchRouter() {
  return (
    <Routes>
      <Route exact path="/" element={
        <HomeRoute title="Video interview software"
          authenticatedComponent={
            <Suspense fallback={<LoadingContainer />}>
              <Home />
            </Suspense>
          }
          unauthenticatedComponent={
            <Suspense fallback={<LoadingContainer />}>
              <HomeLandingPage />
            </Suspense>
          } />
      }/>
      <Route exact path="/login_choice" element={
        <UnauthenticatedRoute title="Login - select account type" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <LoginPathChoice />
          </Suspense>
        </UnauthenticatedRoute>
      } />
      <Route exact path="login" element={
        <UnauthenticatedRoute title="Candidate login" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <Login />
          </Suspense>
        </UnauthenticatedRoute>
      } />
      <Route exact path="/org_login" element ={
        <UnauthenticatedRoute title="Organisation login" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <Login isOrganisation={true} />
          </Suspense>
        </UnauthenticatedRoute>
      } />
      <Route exact path="/signup" element={
        <SignupRoute title="Candidate signup" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <SignUpWizard />
          </Suspense>
        </SignupRoute>
      } />
      <Route exact path="/org_signup" element={
        <OrgSignupRoute title="Organisation signup" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <OrganisationSignUpWizard />
          </Suspense>
        </OrgSignupRoute>
      } />
      <Route exact path="/org_join" element={
        <OrgSignupRoute title="Organisation user signup" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <OrganisationSignUpWizard isExistingOrganisation />
          </Suspense>
        </OrgSignupRoute>
      } />
      <Route exact path="/links/user/:id" element={
        <OrgMixedRoute title="Join organisation account" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <Links type={Link.linkTypes.JOIN_ACCOUNT} />
          </Suspense>
        </OrgMixedRoute>
      } />
      <Route exact path="/org_login/reset" element={
        <UnauthenticatedRoute title="Reset password">
          <Suspense fallback={<LoadingContainer/>}>
            <ResetPasswordWizard isOrganisation={true} />
          </Suspense>
        </UnauthenticatedRoute>
      } />
      <Route exact path="/login/reset" element={
        <UnauthenticatedRoute title="Reset password">
          <Suspense fallback={<LoadingContainer/>}>
            <ResetPasswordWizard isOrganisation={false} />
          </Suspense>
        </UnauthenticatedRoute>
      } />
      <Route exact path="/links/invite/:id" element={
        <CandidateMixedRoute title="Your invitation to apply" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <Links type={Link.linkTypes.INVITE} />
          </Suspense>
        </CandidateMixedRoute>
      } />
      <Route exact path="/links/invite/:id/convert" element={
        <CandidateAuthenticatedRoute title="Your invitation to apply">
          <Suspense fallback={<LoadingContainer/>}>
            <LinksConvert type={Link.linkTypes.INVITE} />
          </Suspense>
        </CandidateAuthenticatedRoute>
      } />
      <Route exact path="/invites/:id" element={
        <CandidateAuthenticatedRoute title="Your invitation to apply">
          <Suspense fallback={<LoadingContainer/>}>
            <InviteContainer />
          </Suspense>
        </CandidateAuthenticatedRoute>
      } />
      <Route exact path="/invites/:inviteId/application/edit" element={
        <CandidateAuthenticatedRoute title="Editing your application">
          <Suspense fallback={<LoadingContainer/>}>
            <ApplicationWizard />
          </Suspense>
        </CandidateAuthenticatedRoute>
      } />
      <Route exact path="/invites/:inviteId/application/view" element={
        <CandidateAuthenticatedRoute title="Your application">
          <Suspense fallback={<LoadingContainer/>}>
            <ApplicationContainer />
          </Suspense>
        </CandidateAuthenticatedRoute>
      }/>
      <Route exact path="/invites/:inviteId/analytics" element={
        <CandidateAuthenticatedRoute title="Your application analytics">
          <Suspense fallback={<LoadingContainer/>}>
            <InviteAnalytics />
          </Suspense>
        </CandidateAuthenticatedRoute>
      } />
      <Route exact path="/links/user/:id/convert" element={
        <OrgAuthenticatedRoute title="Join organisation account">
          <Suspense fallback={<LoadingContainer/>}>
            <LinksConvert type={Link.linkTypes.JOIN_ACCOUNT} />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/links/sharedjob/:id" element={
        <OrgMixedRoute title="Invite to shared applicant list" showLoginNav={false}>
          <Suspense fallback={<LoadingContainer/>}>
            <Links type={Link.linkTypes.APPLICANT_LIST} />
          </Suspense>
        </OrgMixedRoute>
      } />
      <Route exact path="/sharedjobs" element={
        <OrgAuthenticatedRoute title="Jobs shared with my organisation">
          <Suspense fallback={<LoadingContainer/>}>
            <JobsSharedWithMe />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/links/sharedjob/:id/convert" element={
        <OrgAuthenticatedRoute title="Invite to shared applicant list" exact path="/links/sharedjob/:id/convert">
          <Suspense fallback={<LoadingContainer/>}>
            <LinksConvert type={Link.linkTypes.APPLICANT_LIST} />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/invites/:id" element={
        <OrgAuthenticatedRoute title="Candidate application">
          <Suspense fallback={<LoadingContainer/>}>
            <InviteContainer />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/organisations/:orgId/jobs/:jobId/review" element={
        <OrgAuthenticatedRoute title="Candidates for job">
          <Suspense fallback={<LoadingContainer/>}>
            <CandidateFeed />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/organisations/:orgId/jobs/:jobId/shortlist" element={
        <OrgAuthenticatedRoute title="Shortlisted candidates for job">
          <Suspense fallback={<LoadingContainer/>}>
            <CandidateFeed filterOptions={[statusFilterTypes.SHORTLISTED]} />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/organisations/:orgId/jobs/:jobId/edit" element={
        <OrgAuthenticatedRoute title="Editing your job">
          <Suspense fallback={<LoadingContainer/>}>
            <JobWizard />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/organisations/:orgId/jobs/:jobId/view" element={
        <OrgAuthenticatedRoute title="Your job">
          <Suspense fallback={<LoadingContainer/>}>
            <JobContainer />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/organisations/:orgId/jobs/:jobId/users/:userId/invites/:inviteId" element={
        <OrgAuthenticatedRoute title="Candidate application for job">
          <Suspense fallback={<LoadingContainer/>}>
            <CandidateContainer />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/organisations/:orgId/jobs/:jobId/analytics" element={
        <OrgAuthenticatedRoute title="Your job analytics">
          <Suspense fallback={<LoadingContainer/>}>
            <JobAnalytics />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/settings" element={
        <AuthenticatedRoute title="Settings">
          <Suspense fallback={<LoadingContainer/>}>
            <CandidateSettings />
          </Suspense>
        </AuthenticatedRoute>
      } />
      <Route exact path="/org_settings" element={
        <OrgAuthenticatedRoute title="Settings">
          <Suspense fallback={<LoadingContainer/>}>
            <OrganisationSettings />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/select_subscription" element={
        <OrgAuthenticatedRoute title="Select subscription">
          <Suspense fallback={<LoadingContainer/>}>
            <Products showFreeTier={false} />
          </Suspense>
        </OrgAuthenticatedRoute>
      } />
      <Route exact path="/legal" element={
        <BasicRoute title="Terms">
          <Suspense fallback={<LoadingContainer/>}>
            <LegalContainer />
          </Suspense>
        </BasicRoute>
      } />
      <Route exact path="/pricing" element={
        <BasicRoute title="Pricing">
          <Suspense fallback={<LoadingContainer/>}>
            <PricingContainer />
          </Suspense>
        </BasicRoute>
      } />
      <Route exact path="/privacy" element={
        <BasicRoute title="Privacy" exact path="/privacy">
          <Suspense fallback={<LoadingContainer/>}>
            <PrivacyContainer />
          </Suspense>
        </BasicRoute>
      } />
      <Route path="*" element={<Navigate to="/"/>} />
    </Routes>
  );
}