export const common = {
    video: {
        MAX_LENGTH_ERROR_MARGIN: 0.5
    }
  }

export const dev_shared = {
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "dev-pitch-infra-s3-uploads4f6eb0fd-1m36v1htar24l",
        PUBLIC_RESOURCES_BUCKET: "dev-pitch-infra-s3-resources431a4779-mi8q7ius5dza"
    }
};

export const staging_shared = {
  s3: {
      REGION: "ap-southeast-2",
      BUCKET: "staging-pitch-infra-s3-uploads4f6eb0fd-13a3910knas0e",
      PUBLIC_RESOURCES_BUCKET: "staging-pitch-infra-s3-resources431a4779-17zs00ot803qq"
  }
}

export const prod_shared = {
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "prod-pitch-infra-s3-uploads4f6eb0fd-hfoy27o5mlfi",
    PUBLIC_RESOURCES_BUCKET: "prod-pitch-infra-s3-resources431a4779-1s50thl3jfh6h"
  }
}