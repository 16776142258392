/* eslint-disable class-methods-use-this */
import { PitchError } from "./exceptions";
import {standardStyles } from "./enums";
import Serializer, { Serializable } from "./utils";
import { DateTime } from "luxon";

export class BaseDynamoClass extends Serializable {
    constructor(useCounts = true){
        super();
        if(useCounts){
            this.counts = {};
            this.countChanges = {};
            this.parentCountChanges = {};
        }
        this.dirty = false;
        this.dirtyFields = [];
        this._createdDate = Date.now();
        this._updatedDate = this.createdDate;
    }
    static splitPrefix(key){
        return key.split("#")[0];
    }
    static getPKPrefix(){
        throw new PitchError("This method must be overridden");
    }
    static getSKPrefix(){
        throw new PitchError("This method must be overridden");
    }
    static isPermissionCheckNeeded(){
        return true;
    }
    isPermissionCheckNeeded(){
        return true;
    }
    getPK(){
        throw new PitchError("This method must be overridden");
    }
    getSK(){
        throw new PitchError("This method must be overridden");
    }
    static getIndexPermissionKey(){
        throw new PitchError("This method must be overridden");
    }
    getIndexPermissionKey(){
        throw new PitchError("This method must be overridden");
    }
    getKey(){
        return {
            PK: this.getPK(),
            SK: this.getSK()
        }
    }
    getKeyString(){
        return this.getPK() + "#" + this.getSK();
    }
    getParentKey(){
        return null;
    }
    getParentTableName(){
        return null;
    }
    static generateKey(){
        throw new PitchError("This method must be overridden");
    }
    static generatePK(){
        throw new PitchError("This method must be overridden");
    }
    static generateGSIPK(){
        //Receives index number and key
        throw new PitchError("This method must be overridden");
    }
    static generateGSISK(){
        //Receives index number and key
        throw new PitchError("This method must be overridden");
    }
    getGlobalIndices(){
        throw new PitchError("This method must be overridden");
    }
    getDynamoObject(){
        const obj = {
            ...this,
            className: this.className,
            ...this.getGlobalIndices(),
            PK: this.getPK(),
            SK: this.getSK()
        }
        return obj;
    }
    getDynamoObjectForUpdate(){
        const obj = {
            ...this.getGlobalIndices()
        };
        if(this.dirtyFields.length > 0) this.markFieldDirty("_updatedDate");
        this.dirtyFields.forEach(fieldName => {
            if(!Object.prototype.hasOwnProperty.call(this, fieldName)) throw new PitchError(`Field (${fieldName})set as dirty but doesn't exist on parent object (${this.constructor.name})`);
            obj[fieldName] = this[fieldName]
        });
        return obj;
    }
    markFieldDirty(fieldName){
        this._updatedDate = Date.now();
        if(!this.dirtyFields.includes(fieldName)) this.dirtyFields.push(fieldName);
        this.dirty = true;
    }
    markClean(){
        this.dirty = false;
        this.dirtyFields = [];
        this.countChanges = {};
        this.parentCountChanges = {};
    }
    isDirty(){
        return this.dirty;
    }
    /**
     * @param {number} createdDate
     */
    set createdDate(createdDate){
        this._createdDate = createdDate;
        this.markFieldDirty("_createdDate");
    }
    get createdDate(){
        return this._createdDate;
    }
    /**
     * @param {User} createdBy
     */
    set createdBy(createdBy){
        this._createdBy = createdBy.getKey();
        this.markFieldDirty("_createdBy");
    }
    get createdBy(){
        return this._createdBy;
    }
    /**
     * @param {string} lockKey
     */
    set lockKey(lockKey){
        this._lockKey = lockKey;
        this.markFieldDirty("_lockKey");
    }
    get lockKey(){
        return this._lockKey;
    }
    static hasLockKey(){
        return true;
    }
    /**
     * @param {number} updatedDate
     */
    set updatedDate(updatedDate){
        this._updatedDate = updatedDate;
        this.markFieldDirty("_updatedDate");
    }
    get updatedDate(){
        return this._updatedDate;
    }
    /**
     * @param {User} updatedBy
     */
    set updatedBy(updatedBy){
        this._updatedBy = updatedBy.getKey();
        this.markFieldDirty("_updatedBy");
    }
    get updatedBy(){
        return this._updatedBy;
    }
    /**
     * @param {number} status
     */
    set status(status){
        //If no status change then exit
        if(this.status === status) return;
        if(!this.validateStatus(status)) return;
        //Check if parent counts already have a status set. If they do, subtract 1. If they don't and status has been set, then set to -1.
        if(this.parentCountChanges[this.constructor.getClassName() + this._status]){
            this.parentCountChanges[this.constructor.getClassName() + this._status] -= 1;
        }else if(this.status || this.status === 0){
            this.parentCountChanges[this.constructor.getClassName() + this._status] = -1;
        }
        //Set status
        this._status = status;
        //Now increase or set counts
        if(this.parentCountChanges[this.constructor.getClassName() + this._status]){
            this.parentCountChanges[this.constructor.getClassName() + this._status] += 1;
        }else{
            this.parentCountChanges[this.constructor.getClassName() + this._status] = 1;
        }
        this.markFieldDirty("_status");
    }
    get status(){
        return this._status;
    }
    validateStatus(){
        //Override this method - 1 argument passed, status
        return true;
    }
    changeCount(key, value, includeOnParent = true){
        if(value === 0) return;
        if(this.counts[key]){
            this.counts[key] += value;
        }else{
            this.counts[key] = value;
        }
        if(this.countChanges[key]){
            this.countChanges[key] += value;
        }else{
            this.countChanges[key] = value;
        }
        if(includeOnParent){
            if(this.parentCountChanges[key]){
                this.parentCountChanges[key] += value;
            }else{
                this.parentCountChanges[key] = value;
            }
        }
    }
    switchCounts(key, value, includeOnParent = true){
        this.changeCount(key, value, includeOnParent);
        Object.entries(this.counts).forEach(([countKey]) => {
            if(countKey !== key) this.setCount(countKey, 0, includeOnParent)
        });
    }
    changeCounts(changes, includeOnParent = true){
        if(Object.keys(changes).length === 0) return;
        Object.entries(changes).forEach(([key, value]) => this.changeCount(key, value, includeOnParent));
    }
    setCount(key, value, includeOnParent = true){
        if(this.counts[key]){
            if(this.countChanges[key]) {
                this.countChanges[key] += value - this.counts[key];
            }else{
                this.countChanges[key] = value - this.counts[key];
            }
        }else{
            this.countChanges[key] = value;
        }
        if(this.countChanges[key] === 0) delete this.countChanges[key];
        this.counts[key] = value;
        if(includeOnParent){
            this.parentCountChanges[key] = this.countChanges[key];
            if(this.parentCountChanges[key] === 0) delete this.parentCountChanges[key];
        }
    }
    setCounts(counts, includeOnParent = true){
        if(Object.keys(counts).length === 0) return;
        Object.entries(counts).forEach(([key, value]) => this.setCount(key, value, includeOnParent));
    }
    hasCountChanges(){
        return Object.keys(this.countChanges).length > 0;
    }
    getCountChanges(){
        return this.countChanges;
    }
    getCountByKey(key, defaultValue = 0){
        return this.counts[key] ? this.counts[key] : defaultValue;
    }
    getAverageByKeys(keyNumerator, keyDenominator, decimals = 2, NaNReplacement = null){
        return Number.meanAverage(this.getCountByKey(keyNumerator), this.getCountByKey(keyDenominator), decimals, NaNReplacement);
    }
    getCountByKeyFilter(fnc){
        return Object.entries(this.counts).reduce((prevValue, [key, val]) => fnc(key) ? prevValue + val : prevValue, 0);
    }
    getParentCountChanges(){
        return Object.filter(this.parentCountChanges, val => val !== 0);
    }
    static getColumnsForExport(){
        throw new PitchError("This method must be overridden");
    }
    getObjectForExport(){
        throw new PitchError("This method must be overridden");
    }
}

export class Permission extends BaseDynamoClass {
    static permissionTypes = {
        NONE: 0,
        VIEW: 10,
        VIEW_CANDIDATE: 15,
        VIEW_ORGANISATION: 16,
        VIEW_OWNER: 17,
        DECIDE: 20,
        SHARE: 30,
        UPDATE: 40,
        CREATE: 50,
        DELETE: 60,
        ADMIN: 70
    }
    static permissionNames = {
        10: "View",
        15: "Candidate View",
        17: "View All",
        20: "Decide",
        30: "Share",
        40: "Update",
        50: "Create",
        60: "Delete",
        70: "Admin"
    }
    constructor(parentKey, childKey, userId, permission, isOrganisationUser = false){
        super(true);
        if(parentKey) this.parentKey = parentKey;
        if(userId) this.userId = userId;
        if(permission || permission === 0) this.permission = permission;
        if(childKey) this.childKey = childKey;
        this.isOrganisationUser = isOrganisationUser;
    }
    static getClassName(){
        return "Permission";
    }
    static getPKPrefix({isOrganisationUser}){
        return isOrganisationUser ? "ORGUSER#" : "USER#";
    }
    static getSKPrefix({parentType}){
        return parentType + "#";
    }
    updateUserId(newId){
        this.parentKey = this.parentKey.replace(this.userId, newId);
        this.childKey = this.childKey.replace(this.userId, newId);
        this.userId = newId;
    }
    isWildcard(){
        return (this.childKey && this.childKey.endsWith("*")) || (this.parentKey && this.parentKey.endsWith("*"));
    }
    getChildKeyPrefix(){
        return this.childKey.split("#")[0];
    }
    getPK(){
        return Permission.getPKPrefix({isOrganisationUser: this.isOrganisationUser}) + this.userId
    }
    getSK(){
        return this.parentKey + "#" + this.childKey;
    }
    getEntityKey(){
        return {
            PK: this.parentKey,
            SK: this.childKey
        }
    }
    getGlobalIndices(){
        return {
            GSI1PK: this.getSK(),
            GSI1SK: this.getPK()
        };
    }
    static generateKey({parentKey, childKey, userId, isOrganisationUser = false}){
        return {
            PK: Permission.getPKPrefix({isOrganisationUser}) + userId,
            SK: parentKey + "#" + childKey
        }
    }
    static generatePK({userId, isOrganisationUser = false}){
        return Permission.getPKPrefix({isOrganisationUser}) + userId;
    }
    /**
     * @param {number} permission
     */
    set permission(permission){
        this._permission = permission;
        this.markFieldDirty("_permission");
    }
    get permission(){
        return this._permission;
    }
}

export class Location extends Serializable {
    static getClassName(){
        return "Location";
    }
}

export class Subscriptions extends Serializable {
    /**
     * @param {boolean} master
     */
    set master(master){
        this._master = master;
    }
    get master(){
        return this._master;
    }
    static getClassName(){
        return "Subscriptions";
    }
}

export class SearchResult extends Serializable {
    constructor(count, scannedCount, startKey){
        super();
        this.items = [];
        if(count) this.count = count;
        if(scannedCount) this.scannedCount = scannedCount;
        if(startKey) this.startKey = startKey;
    }
    static getClassName(){
        return "SearchResult";
    }
}

export class Subscription extends Serializable {
    static getClassName(){
        return "Subscription";
    }
}

export class Organisation extends BaseDynamoClass {
    constructor(orgId){
        super();
        if(orgId) this.orgId = orgId;
        this.subscription = new Subscription();
        //Set default subscription (will be overridden by deserialisation)
        this.subscription.status = "free";
        this.subscription.applicantsLimit = 5;
        this.subscription.jobsLimit = 1;
    }
    static freeTierLimits = {
        JOBS_LIMIT: 1,
        APPLICANTS_LIMIT: 5
    }
    static getPKPrefix(){
        return "ORG#";
    }
    static getSKPrefix(){
        return "METADATA#";
    }
    static isPermissionCheckNeeded(permission){
        return permission > Permission.permissionTypes.VIEW;
    }
    isPermissionCheckNeeded(permission){
        return permission > Permission.permissionTypes.VIEW;
    }
    getSKPrefix(){
        return "METADATA#";
    }
    getPK(){
        if(!this.orgId) throw new PitchError("orgId is not set");
        return Organisation.getPKPrefix() + this.orgId;
    }
    getSK(){
        if(!this.orgId) throw new PitchError("orgId is not set");
        return Organisation.getSKPrefix() + this.orgId;
    }
    static generateKey({orgId}){
        return {
            PK: Organisation.getPKPrefix() + orgId,
            SK: Organisation.getSKPrefix() + orgId
        }
    }
    static generatePK({orgId}){
        return Organisation.getPKPrefix() + orgId;
    }
    getGlobalIndices(){
        if(this.subscription && this.subscription.customerId){
            return {
                GSI1PK: "STRIPE#" + this.subscription.customerId,
                GSI1SK: "ORG#" + this.orgId
            };
        }
        return {};
    }
    static generateGSIPK(indexNumber, key){
        switch(indexNumber){
            case 1:
                if(key.stripeId){
                    return "STRIPE#" + key.stripeId;
                }
                throw new PitchError("Stripe id not set");
            default:
                throw new PitchError("Organisation does not have index " + indexNumber);
        }
    }
    static organisationRegistrationStatus = {
        STARTED: 0,
        COMPLETED: 1,
        DELETED: 2
    }
    getIgnoreFields(view){
        if(view < Permission.permissionTypes.ORGANISATION) return ["counts", "primaryUserId", "balance", "_status", "_createdDate", "_updatedDate", "_subscription"];
        return [];
    }
    /**
     * @param {string} primaryUserId
     */
    set primaryUserId(primaryUserId){
        this._primaryUserId = primaryUserId;
        this.markFieldDirty("_primaryUserId");
    }
    get primaryUserId(){
        return this._primaryUserId;
    }
    /**
     * @param {number} balance
     */
    set balance(balance){
        this._balance = balance;
        this.markFieldDirty("_balance");
    }
    get balance(){
        return this._balance;
    }
    /**
     * @param {string} organisationName
     */
    set organisationName(organisationName){
        this._organisationName = organisationName;
        this.markFieldDirty("_organisationName");
    }
    get organisationName(){
        return this._organisationName;
    }
    /**
     * @param {string} recruitingOrganisation
     */
    set recruitingOrganisation(recruitingOrganisation){
        this._recruitingOrganisation = recruitingOrganisation;
        this.markFieldDirty("_recruitingOrganisation");
    }
    get recruitingOrganisation(){
        return this._recruitingOrganisation;
    }
    /**
     * @param {string} organisationName
     */
    set isRecruiter(isRecruiter){
        this._isRecruiter = isRecruiter;
        this.markFieldDirty("_isRecruiter");
    }
    get isRecruiter(){
        return this._isRecruiter;
    }

    /**
     * @param {Location} location
     */
    set location(location){
        if(!(location instanceof Location)){
            throw new PitchError("Value is not an instance of Location");
        }
        this.markFieldDirty("_location");
        this._location = location;
    }
    get location(){
        return this._location;
    }
    static getClassName(){
        return "Organisation";
    }
    /**
     * @param {Subscription} subscription
     */
    set subscription(subscription){
        if(!(subscription instanceof Subscription)){
            throw new PitchError("Value is not an instance of Subscription");
        }
        this.markFieldDirty("_subscription");
        this._subscription = subscription;
    }
    get subscription(){
        return this._subscription;
    }
    countOfActiveJobs(){
        return this.getCountByKeyFilter((key) => key === "Job2");
    }
    countOfApplicantsInCurrentPeriod(){
        const now = DateTime.now().toUTC();
        return this.getCountByKey(`${now.get('year')}${now.get('month')}applicants`, 0);
    }
    hasReachedLimit(){
        if(this.subscription && (this.subscription.status === "active" || this.subscription.status === 'trialing') && this.subscription.currentPeriodEnd > Date.now()){
            return (this.countOfActiveJobs() >= this.subscription.jobsLimit) ? "You've reached the limit of posted jobs for your subscription. Close some other jobs or upgrade your subscription." : false;
        }
        return (this.countOfActiveJobs() >= Organisation.freeTierLimits.JOBS_LIMIT) ? "You've reached the limit of posted jobs for the free tier. Close some other jobs or upgrade your subscription." : false;
    }
    hasActiveSubscription(){
        return this.subscription && (this.subscription.status === "active" || this.subscription.status === 'trialing') && this.subscription.currentPeriodEnd > Date.now();
    }
    static getColumnsForExport(){
        return ["organisationName"]
    }
    getObjectForExport(){
        return {
            organisationName: this.organisationName
        }
    }
}

export class User extends BaseDynamoClass {
    constructor(userId, emailAddress){
        super();
        if(userId) this.userId = userId;
        if(emailAddress) this.emailAddress = emailAddress;
    }
    getIgnoreFields(view){
        if(view < Permission.permissionTypes.VIEW_OWNER) return ["counts", "_mobileNumber", "_subscriptions", "_identityId", "_createdDate", "_updatedDate", "_termsVersion", "_status", "termsAgreed", "_emailAddress"];
        return [];
    }
    /**
     * @param {string} emailAddress
     */
    set emailAddress(emailAddress){
        this._emailAddress = emailAddress;
        this.markFieldDirty("_emailAddress");
    }
    get emailAddress(){
        return this._emailAddress;
    }
    /**
     * @param {string} identityId
     */
    set identityId(identityId){
        this._identityId = identityId;
        this.markFieldDirty("_identityId");
    }
    get identityId(){
        return this._identityId;
    }
    /**
     * @param {string} firstName
     */
    set firstName(firstName){
        this._firstName = firstName;
        this.markFieldDirty("_firstName");
    }
    get firstName(){
        return this._firstName;
    }
    /**
     * @param {string} lastName
     */
    set lastName(lastName){
        this._lastName = lastName;
        this.markFieldDirty("_lastName");
    }
    get lastName(){
        return this._lastName;
    }
    /**
     * @param {string} mobileNumber
     */
    set mobileNumber(mobileNumber){
        this._mobileNumber = mobileNumber;
        this.markFieldDirty("_mobileNumber");
    }
    get mobileNumber(){
        return this._mobileNumber;
    }
    /**
     * @param {boolean} termsAgreed
     */
    set termsAgreed(termsAgreed){
        this._termsAgreed = termsAgreed;
        this.markFieldDirty("_termsAgreed");
    }
    get termsAgreed(){
        return this._termsAgreed;
    }
    /**
     * @param {string} termsVersion
     */
    set termsVersion(termsVersion){
        this._termsVersion = termsVersion;
        this.markFieldDirty("_termsVersion");
    }
    get termsVersion(){
        return this._termsVersion;
    }
    static generateKey({userId}){
        return {
            PK: User.getPKPrefix() + userId,
            SK: User.getSKPrefix() + userId
        }
    }
    static getClassName(){
        return "User";
    }
    static getPKPrefix(){
        return "USER#";
    }
    static getSKPrefix(){
        return "USER#";
    }
    getSKPrefix(){
        return "USER#";
    }
    getPK(){
        if(!this.userId) throw new PitchError("userId is not set");
        return User.getPKPrefix() + this.userId;
    }
    getSK(){
        if(!this.userId) throw new PitchError("userId is not set");
        return User.getSKPrefix() + this.userId;
    }
    static generatePK({userId}){
        if(!userId) throw new PitchError("userId is not set");
        return User.getPKPrefix() + userId;
    }
    getGlobalIndices(){
        return {};
    }
    static userRegistrationStatus = {
        UNCONFIRMED: 0,
        CONFIRMED: 1,
        COMPLETED: 2,
        DELETED: 3,
        PASSWORD_CHANGE_REQUIRED: 4
    };
    static userTypes = {
        CANDIDATE: 0
    };
    /**
     * @param {Subscriptions} subscriptions
     */
    set subscriptions(subscriptions){
        if(!(subscriptions instanceof Subscriptions)){
            throw new PitchError("Value is not an instance of Subscriptions");
        }
        this.markFieldDirty("_subscriptions");
        this._subscriptions = subscriptions;
    }
    get subscriptions(){
        return this._subscriptions;
    }

    /**
     * @param {Location} location
     */
    set location(location){
        if(location && !(location instanceof Location)){
            throw new PitchError("Value is not an instance of Location");
        }
        this.markFieldDirty("_location");
        this._location = location;
    }
    get location(){
        return this._location;
    }
}

export class OrganisationUser extends User {
    constructor(userId, orgId){
        if(userId) {
            super(userId)
        }else{
            super();
        }
        if(orgId) this.orgId = orgId;
    }
    getIgnoreFields(view){
        const fields = [];
        if(view < Permission.permissionTypes.VIEW_OWNER) fields.push("_mobileNumber", "_subscriptions", "_identityId", "_termsVersion", "termsAgreed", "_emailAddress", );
        if(view < Permission.permissionTypes.ORGANISATION) fields.push("counts", "_createdDate", "_updatedDate", "_status", "_isAdministrator");
        return fields;
    }
    static organisationUserTypes = {
        HIRING_MGR: 0,
        TA: 1,
        OTHER_MGR: 2,
        RECRUITER: 3,
        ADMINISTRATOR: 4
    }
    static organisationUserTypesNames = {
        0: "Hiring manager",
        1: "Talent acquisition",
        2: "Other manager",
        3: "Recruitment agent",
        4: "Pitchli administrator"
    }
    static organisationUserTypesStyles = {
        0: standardStyles.SECONDARY2,
        1: standardStyles.COMPLEMENT,
        2: standardStyles.SECONDARY1,
        3: standardStyles.SECONDARY2,
        4: standardStyles.PRIME
    }
    static getPKPrefix(){
        return "ORG#";
    }
    getPK(){
        if(!this.orgId) throw new PitchError("orgId is not set");
        return OrganisationUser.getPKPrefix() + this.orgId;
    }
    static getSKPrefix(){
        return "USER#";
    }
    getSKPrefix(){
        return "USER#";
    }
    getParentKey(){
        return Organisation.generateKey({orgId: this.orgId});
    }
    static generateKey({orgId, userId}){
        return {
            PK: OrganisationUser.getPKPrefix() + orgId,
            SK: OrganisationUser.getSKPrefix() + userId
        }
    }
    static generatePK({orgId}){
        if(!orgId) throw new PitchError("orgId is not set");
        return OrganisationUser.getPKPrefix() + orgId;
    }
    static getClassName(){
        return "OrganisationUser";
    }
    /**
     * @param {string} roleType
     */
    set roleType(roleType){
        this.markFieldDirty("_roleType");
        this._roleType = roleType;
    }
    get roleType(){
        return this._roleType;
    }
    /**
     * @param {boolean} isAdministrator
     */
    set isAdministrator(isAdministrator){
        this.markFieldDirty("_isAdministrator");
        this._isAdministrator = isAdministrator;
    }
    get isAdministrator(){
        return this._isAdministrator;
    }
}

export class ApplicantDetails extends Serializable {
    constructor(sharePermissions){
        super();
        if(sharePermissions){
            this._shareMobileNumber = sharePermissions.shareMobileNumber || false;
            this._shareFirstName = sharePermissions.shareFirstName || false;
            this._shareLastName = sharePermissions.shareLastName || false;
            this._shareEmailAddress = sharePermissions.shareEmailAddress || false;
            this._shareLocation = sharePermissions.shareLocation || false;
        }
    }
    blankContactDetails(){
        this._shareMobileNumber = false;
        this._shareFirstName = false;
        this._shareLastName = false;
        this._shareEmailAddress = false;
        this._shareLocation = false;
        this.reApplySharePermissions();
    }
    reApplySharePermissions(){
        this.populateDetails({
            firstName: this.firstName,
            lastName: this.lastName,
            mobileNumber: this.mobileNumber,
            location: this.location,
            emailAddress: this.emailAddress
        })
    }
    /**
    * @param {SharePermissions} sharePermissions
    */
   set sharePermissions(sharePermissions){
        if(sharePermissions){
            this._shareMobileNumber = sharePermissions.mobileNumber || false;
            this._shareFirstName = sharePermissions.firstName || false;
            this._shareLastName = sharePermissions.lastName || false;
            this._shareEmailAddress = sharePermissions.emailAddress || false;
            this._shareLocation = sharePermissions.location || false;
        }
    }
    get sharePermissions(){
        return {
            shareMobileNumber: this._shareMobileNumber,
            shareEmailAddress: this._shareEmailAddress,
            shareFirstName: this._shareFirstName,
            shareLastName: this._shareLastName,
            shareLocation: this._shareLocation
        }
    }
    /**
        * @param {Location} location
        */
    set location(location){
        if(location && !(location instanceof Location)){
            throw new PitchError("Value is not an instance of Location");
        }
        this._location = this._shareLocation ? location : null;
    }
    get location(){
        return this._location;
    }
    /**
        * @param {string} firstName
        */
    set firstName(firstName){
        this._firstName = this._shareFirstName ? firstName : null;
    }
    get firstName(){
        return this._firstName;
    }
    /**
        * @param {string} lastName
        */
    set lastName(lastName){
        this._lastName = this._shareLastName ? lastName : null;
    }
    get lastName(){
        return this._lastName;
    }
    /**
        * @param {string} mobileNumber
        */
    set mobileNumber(mobileNumber){
        this._mobileNumber = this._shareMobileNumber ? mobileNumber : null;
    }
    get mobileNumber(){
        return this._mobileNumber;
    }
    /**
        * @param {string} emailAddress
        */
    set emailAddress(emailAddress){
        this._emailAddress = this._shareEmailAddress ? emailAddress : null;
    }
    get emailAddress(){
        return this._emailAddress;
    }
    /**
        * @param {boolean} shareEmailAddress
        */
    set shareEmailAddress(shareEmailAddress){
        this._shareEmailAddress = shareEmailAddress;
    }
    get shareEmailAddress(){
        return this._shareEmailAddress;
    }
    /**
        * @param {boolean} shareMobileNumber
        */
    set shareMobileNumber(shareMobileNumber){
        this._shareMobileNumber = shareMobileNumber;
    }
    get shareMobileNumber(){
        return this._shareMobileNumber;
    }
    /**
        * @param {boolean} shareFirstName
        */
    set shareFirstName(shareFirstName){
        this._shareFirstName = shareFirstName;
    }
    get shareFirstName(){
        return this._shareFirstName;
    }
    /**
        * @param {boolean} shareLastName
        */
    set shareLastName(shareLastName){
        this._shareLastName = shareLastName;
    }
    get shareLastName(){
        return this._shareLastName;
    }
    /**
        * @param {boolean} shareLocation
        */
    set shareLocation(shareLocation){
        this._shareLocation = shareLocation;
    }
    get shareLocation(){
        return this._shareLocation;
    }
    /**
     *
     * @param {User} user
     */
    populateDetails(user){
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.emailAddress = user.emailAddress;
        this.mobileNumber = user.mobileNumber;
        this.location = user.location;
    }
    static getClassName(){
        return "ApplicantDetails";
    }
    static getColumnsForExport(){
        return [
            "firstName",
            "lastName",
            "emailAddress",
            "mobileNumber",
            "locality",
            "postcode",
            "state",
            "country"
        ]
    }
    getFlatObject(){
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            emailAddress: this.emailAddress,
            mobileNumber: this.mobileNumber,
            locality: this.location?.locality,
            postcode: this.location?.postcode,
            state: this.location?.state,
            country: this.location?.country
        }
    }
}

export class Pitch extends BaseDynamoClass {

    constructor(userId, pitchId, identityId, mediaUri, privacyLevel, allowUnauth, originalFileName, extension, height, width, duration){
        super();
        if(pitchId) this.pitchId = pitchId;
        this.userId = userId;
        this.identityId = identityId;
        this.mediaUri = mediaUri;
        this._privacyLevel = privacyLevel;
        this._allowUnauth = allowUnauth || false;
        this.originalFileName = originalFileName;
        this.extension = extension;
        this.height = height;
        this.width = width;
        this.duration = duration;
    }
    static privacyLevels = {
        PUBLIC: 0,
        PROTECTED: 1,
        PRIVATE: 2,
    };
    static pitchStatusTypes = {
        CREATED: 0,
        DELETED: 1
    }
    static privacyLevelNames = {
        0: "public",
        1: "protected",
        2: "private",
    };
    static getPKPrefix(){
        return "USER#";
    }
    static getSKPrefix(){
        return "PITCH#";
    }
    getSKPrefix(){
        return "PITCH#";
    }
    getPK(){
        if(!this.userId) throw new PitchError("userId is not set");
        return Pitch.getPKPrefix() + this.userId;
    }
    getSK(){
        if(!this.pitchId) throw new PitchError("pitchId is not set");
        return Pitch.getSKPrefix() + this.pitchId;
    }
    getParentKey(){
        return (this.inviteId ? User.generateKey({userId: this.userId}) : OrganisationUser.generateKey({orgId: this.orgId, userId: this.userId}));
    }
    static generateKey({userId, pitchId}){
        return {
            PK: Pitch.getPKPrefix() + userId,
            SK: Pitch.getSKPrefix() + pitchId
        }
    }
    static generatePK({userId}){
        return Pitch.getPKPrefix() + userId;
    }
    getGlobalIndices(){
        if(this.jobId && this.inviteId){
            return {
                GSI1PK: "ORG#" + this.orgId + "#JOB#" + this.jobId,
                GSI1SK: "INVITE#" + this.inviteId
            };
        }else if(this.orgId && this.jobId){
            return {
                GSI1PK: "ORG#" + this.orgId + "#JOB#" + this.jobId,
                GSI1SK: "PITCH#" + this.pitchId
            }
        }
    }
    static getIndexPermissionKey(indexNumber, item){
        switch(indexNumber){
            case 0:
                return Pitch.generateKey({userId: item.userId, pitchId: item.pitchId});
            case 1:
                return {PK: "ORG#" + item.orgId, SK: "JOB#" + item.jobId};
            default:
                throw new PitchError("Invite does not have index " + indexNumber);
        }
    }
    getIndexPermissionKey(indexNumber){
        return Pitch.getIndexPermissionKey(indexNumber, this);
    }
    static generateGSIPK(indexNumber, key){
        switch(indexNumber){
            case 1:
                if(key.orgId && key.jobId){
                    return "ORG#" + key.orgId + "#JOB#" + key.jobId;
                }
                return "JOB#" + key.jobId
            default:
                throw new PitchError("Pitch does not have index " + indexNumber);
        }
    }
    static generateGSISK(indexNumber, key){
        switch(indexNumber){
            case 1:
                if(key && key.pitchId){
                    return "PITCH#" + key.pitchId;
                }
                return "PITCH#";
            default:
                throw new PitchError("Pitch does not have index " + indexNumber);
        }
    }
    get s3Key(){
        return Pitch.privacyLevelNames[this.privacyLevel] + "/" + this.identityId + "/" + this.mediaUri;
    }
    static getClassName(){
        return "Pitch";
    }
    get privacyLevel(){
        return this._privacyLevel;
    }
    set privacyLevel(privacyLevel){
        this._privacyLevel = privacyLevel;
        this.markFieldDirty("_privacyLevel");
    }
    get allowUnauth(){
        return this._allowUnauth;
    }
    set allowUnauth(allowUnauth){
        this._allowUnauth = allowUnauth;
        this.markFieldDirty("_allowUnauth");
    }
    set name(name){
        this._name = name;
        this.markFieldDirty("_name");
    }
    get name(){
        return this._name || this.originalFileName;
    }
    hasName(){
        return !!this._name;
    }
}

export class Answer extends Pitch {
    constructor(userId, pitchId, identityId, mediaUri, privacyLevel, allowUnauth, originalFileName, extension, height, width, duration, jobQuestion){
        super(userId, pitchId, identityId, mediaUri, privacyLevel, allowUnauth, originalFileName, extension, height, width, duration);
        this.jobQuestion = jobQuestion;
    }
    set name(name){
        //Don't name answers
        return;
    }
    get name(){
        return this.jobQuestion.text;
    }
    hasName(){
        return true;
    }
    static getClassName(){
        return "Answer";
    }
}

export class Application extends Serializable {

    constructor(){
        super();
        this._pitches = [];
    }
    static appStatusTypes = {
        CREATED: 0,
        PITCH_ADDED: 1,
        SUBMITTED: 2,
        DELETED: 3
    };
    static applicationDecisionStatus = {
        NEW: 0,
        SHORTLISTED: 1,
        REJECTED: 2
    }
    static applicationStatusTypesStyles = {
        0: standardStyles.NEW,
        1: standardStyles.SHORTLISTED,
        2: standardStyles.REJECTED
    };
    static applicationStatusTypesNames = {
        0: "New",
        1: "Shortlisted",
        2: "Rejected"
    };
    getIgnoreFields(view){
        if(view < Permission.permissionTypes.ORGANISATION) return ["_decisionStatus"];
        return [];
    }
    addPitch(pitch){
        if(!(pitch instanceof Pitch)) throw new PitchError("Object is not a Pitch");
        this._pitches.push(pitch);
    }
    addPitchAtIndex(pitch, index){
        if(!(pitch instanceof Pitch)) throw new PitchError("Object is not a Pitch");
        this._pitches[index] = pitch;
    }
    getPitch(pitchId){
        const pitches = this._pitches.filter((pitch) => pitch.pitchId === pitchId);
        if(!pitches.length === 0) throw new PitchError("Pitch doesn't exist on application");
        return pitches[0];
    }
    getPitches(){
        return this._pitches;
    }
    getPitchAtIndex(index){
        return this._pitches[index];
    }
    updatePitch(pitch){
        this.addPitchAtIndex(pitch, this._pitches.findIndex(p => p.pitchId === pitch.pitchId));
    }
    removePitch(pitchId){
        this._pitches = this._pitches.filter((pitch) => pitch.pitchId !== pitchId);
    }
    /**
     * @param {array} pitches
     */
    set pitches(pitches){
        this._pitches = pitches;
    }
    get pitches(){
        return this._pitches;
    }
    /**
     * @param {number} decisionStatus
     */
    set decisionStatus(decisionStatus){
        this._decisionStatus = decisionStatus;
    }
    get decisionStatus(){
        return this._decisionStatus;
    }
    /**
     * @param {number} applicantIndex
     */
    set applicantIndex(applicantIndex){
        this._applicantIndex = applicantIndex;
    }
    get applicantIndex(){
        return this._applicantIndex;
    }
    /**
     * @param {number} submittedDate
     */
    set submittedDate(submittedDate){
        this._submittedDate = submittedDate;
    }
    get submittedDate(){
        return this._submittedDate;
    }
    set applicantDetails(applicantDetails){
        if(!(applicantDetails instanceof ApplicantDetails)){
            throw new PitchError("Tried to set applicantDetails, but object not a applicantDetails object");
        }
        this._applicantDetails = applicantDetails;
    }
    get applicantDetails(){
        return this._applicantDetails;
    }
    static getClassName(){
        return "Application";
    }
    getParentCountChanges(){
        return Object.filter(this.parentCountChanges, val => val !== 0);
    }
}

export class Job extends BaseDynamoClass {

    constructor(orgId, jobId, posterId){
        super();
        this._pitches = [];
        this._jobQuestions = [];
        if(orgId && jobId && posterId) this.orgId = orgId; this.jobId = jobId; this.posterId = posterId;
    }
    static getPKPrefix(){
        return "ORG#";
    }
    static getSKPrefix(){
        return "JOB#";
    }
    getPKPrefix(){
        return "ORG#";
    }
    getSKPrefix(){
        return "JOB#";
    }
    static generateKey({orgId, jobId}){
        return {
            PK: Job.getPKPrefix() + orgId,
            SK: Job.getSKPrefix() + jobId
        }
    }
    static generatePK({orgId}){
        return Job.getPKPrefix() + orgId;
    }
    getPK(){
        if(!this.orgId) throw new PitchError("orgId is not set");
        return Job.getPKPrefix() + this.orgId;
    }
    getSK(){
        if(!this.jobId) throw new PitchError("jobId is not set");
        return Job.getSKPrefix() + this.jobId;
    }
    getParentKey(){
        return Organisation.generateKey({orgId: this.orgId});
    }
    static getIndexPermissionKey(indexNumber, item){
        switch(indexNumber){
            case 1:
                return {PK: "ORG#" + item.sharingOrgId, SK: "JOB#" + item.jobId};
            default:
                throw new PitchError("Job does not have permission index " + indexNumber);
        }
    }
    getIndexPermissionKey(indexNumber){
        return Job.getIndexPermissionKey(indexNumber, this);
    }
    static isPermissionCheckNeeded(permission){
        return permission > Permission.permissionTypes.VIEW_CANDIDATE;
    }
    isPermissionCheckNeeded(permission){
        return permission > Permission.permissionTypes.VIEW_CANDIDATE;
    }
    getGlobalIndices(){
        const indices = {
        };
        if(this.sharingOrgId){
            indices.GSI1PK = "SHARINGORG#" + this.sharingOrgId;
            indices.GSI1SK = "JOB#" + this.jobId;
        }
        if(this.closingDate){
            indices.GSI3PK = "ORG#" + this.orgId;
            indices.GSI3SK = "JOBCLOSINGDATE#" + this.closingDate;
        }
        if(!this.closingDate && this.status >= Job.jobStatusTypes.POSTED){
            indices.GSI3PK = "ORG#" + this.orgId;
            indices.GSI3SK = "JOBCLOSINGDATE#4000000000000";
        }
        return indices;
    }
    static generateGSIPK(indexNumber, key){
        switch(indexNumber){
            case 1:
                return "SHARINGORG#" + key.sharingOrgId;
            case 3:
                return "ORG#" + key.orgId;
            default:
                throw new PitchError("Invite does not have index " + indexNumber);
        }
    }
    static generateGSISK(indexNumber, key){
        switch(indexNumber){
            case 1:
                return "JOB#" + ((key && key.jobId) ? key.jobId : "");
            case 3:
                return "JOBCLOSINGDATE#" + ((key && key.date) ? key.date : "");
            default:
                throw new PitchError("Invite does not have index " + indexNumber);
        }
    }

    static jobStatusTypes = {
        CREATED: 0,
        PITCH_ADDED: 1,
        POSTED: 2,
        DELETED: 3,
        EXPIRED: 4
    };
    static jobStatusTypesNames = {
        0: "Started",
        1: "Pitch added",
        2: "Job posted",
        3: "Job deleted",
        4: "Job expired"
    };

    static jobStatusTypesStyles = {
        0: standardStyles.UNOPENED,
        1: standardStyles.OPENED,
        2: standardStyles.SUBMITTED,
        3: standardStyles.UNOPENED,
        4: standardStyles.UNOPENED
    };
    static questionModes = {
        CREATIVE: 0,  //Uploads allowed, no retry limit
        TEST: 1 //No uploads, 3 retries
    }
    getIgnoreFields(view){
        if(view < Permission.permissionTypes.ORGANISATION) return ["counts", "_status"];
        return [];
    }
    validateStatus(status){
        return !(this.status >= Job.jobStatusTypes.POSTED && status < Job.jobStatusTypes.POSTED)
    }
    /**
     * @param {Location} location
     */
    set location(location){
        if(!(location instanceof Location)){
            throw new PitchError("Tried to set location, but object not a location object");
        }
        this._location = location;
        this.markFieldDirty("_location");
    }
    get location(){
        return this._location;
    }
    addPitch(pitch){
        if(!(pitch instanceof Pitch)) throw new PitchError("Object is not a Pitch");
        this._pitches.push(pitch);
        this.markFieldDirty("_pitches");
    }
    addPitchAtIndex(pitch, index){
        if(!(pitch instanceof Pitch)) throw new PitchError("Object is not a Pitch");
        this._pitches[index] = pitch;
        this.markFieldDirty("_pitches");
    }
    getPitch(pitchId){
        const pitches = this._pitches.filter((pitch) => pitch.pitchId === pitchId);
        if(!pitches.length === 0) throw new PitchError("Pitch doesn't exist on job");
        return pitches[0];
    }
    getPitchAtIndex(index){
        return this._pitches[index];
    }
    getPitches(){
        return this._pitches;
    }
    updatePitch(pitch){
        this.addPitchAtIndex(pitch, this._pitches.findIndex(p => p.pitchId === pitch.pitchId));
    }
    removePitch(pitchId){
        this._pitches = this._pitches.filter((pitch) => pitch.pitchId !== pitchId);
        this.markFieldDirty("_pitches");
    }
    /**
     * @param {array} pitches
     */
    set pitches(pitches){
        this._pitches = pitches;
        this.markFieldDirty("_pitches");
    }
    get pitches(){
        return this._pitches;
    }
    addJobQuestion(question){
        this._jobQuestions.push(question);
        this.markFieldDirty("_jobQuestions");
    }
    getJobQuestions(){
        return this._jobQuestions;
    }
    setJobQuestions(jobQuestions){
        this._jobQuestions = jobQuestions;
        this.markFieldDirty("_jobQuestions");
    }
    get jobQuestions(){
        return this._jobQuestions;
    }
    set jobQuestions(jobQuestions){
        this._jobQuestions = jobQuestions;
        this.markFieldDirty("_jobQuestions");
    }
    removeJobQuestion(question){
        this._jobQuestions = this._jobQuestions.filter((q) => q.questionId !== question.questionId);
        this.markFieldDirty("_jobQuestions");
    }
    /**
     * @param {string} sharingOrgId
     */
    set sharingOrgId(sharingOrgId){
        this._sharingOrgId = sharingOrgId;
        this.markFieldDirty("_sharingOrgId");
    }
    get sharingOrgId(){
        return this._sharingOrgId;
    }
    /**
     * @param {number} openingDate
     */
    set openingDate(openingDate){
        this._openingDate = openingDate;
        this.markFieldDirty("_openingDate");
    }
    get openingDate(){
        return this._openingDate;
    }
    /**
     * @param {string} organisationName
     */
    set organisationName(organisationName){
        this._organisationName = organisationName;
        this.markFieldDirty("_organisationName");
    }
    get organisationName(){
        return this._organisationName;
    }
    /**
     * @param {boolean} isRepresented
     */
    set isRepresented(isRepresented){
        this._isRepresented = isRepresented;
        this.markFieldDirty("_isRepresented");
    }
    get isRepresented(){
        return this._isRepresented;
    }
    /**
     * @param {string} recruitingOrganisation
     */
    set recruitingOrganisation(recruitingOrganisation){
        this._recruitingOrganisation = recruitingOrganisation;
        this.markFieldDirty("_recruitingOrganisation");
    }
    get recruitingOrganisation(){
        return this._recruitingOrganisation;
    }
    /**
     * @param {string} jobTitle
     */
    set jobTitle(jobTitle){
        this._jobTitle = jobTitle;
        this.markFieldDirty("_jobTitle");
    }
    get jobTitle(){
        return this._jobTitle;
    }
    /**
     * @param {string} brief
     */
    set brief(brief){
        this._brief = brief;
        this.markFieldDirty("_brief");
    }
    get brief(){
        return this._brief;
    }
    /**
     * @param {string} managerFirstName
     */
    set managerFirstName(managerFirstName){
        this._managerFirstName = managerFirstName;
        this.markFieldDirty("_managerFirstName");
    }
    get managerFirstName(){
        return this._managerFirstName;
    }
    /**
     * @param {string} managerLastName
     */
    set managerLastName(managerLastName){
        this._managerLastName = managerLastName;
        this.markFieldDirty("_managerLastName");
    }
    get managerLastName(){
        return this._managerLastName;
    }
    /**
     * @param {number} maxPitchLength
     */
    set maxPitchLength(maxPitchLength){
        this._maxPitchLength = maxPitchLength;
        this.markFieldDirty("_maxPitchLength");
    }
    get maxPitchLength(){
        return this._maxPitchLength;
    }
    /**
     * @param {string} tagline
     */
    set tagline(tagline){
        this._tagline = tagline;
        this.markFieldDirty("_tagline");
    }
    get tagline(){
        return this._tagline;
    }
    /**
     * @param {number} closingDate
     */
    set closingDate(closingDate){
        this._closingDate = closingDate;
        this.markFieldDirty("_closingDate");
    }
    get closingDate(){
        return this._closingDate;
    }
    /**
     * @param {boolean} noClosingDate
     */
    set noClosingDate(noClosingDate){
        this._noClosingDate = noClosingDate;
        this.markFieldDirty("_noClosingDate");
    }
    get noClosingDate(){
        return this._noClosingDate;
    }
    /**
     * @param {object} limits
     */
    set limits(limits){
        this._limits = limits;
        this.markFieldDirty("_limits");
    }
    get limits(){
        return this._limits;
    }
    /**
     * @param {boolean} questionMode
     */
    set questionMode(questionMode){
        this._questionMode = questionMode;
        this.markFieldDirty("_questionMode");
    }
    get questionMode(){
        return this._questionMode;
    }
    static getClassName(){
        return "Job";
    }
}

export class JobQuestion extends Serializable {
    constructor(id, text, maxLength){
        super();
        this.questionId =  id;
        this.text = text;
        this.maxLength = maxLength;
    }
    static getClassName(){
        return "JobQuestion";
    }
}

export class Invite extends BaseDynamoClass {

    constructor(userId, inviteId, job){
        super();
        if(userId && inviteId){
            this.userId = userId;
            this.inviteId = inviteId;
        }
        if(job && (job._status !== Job.jobStatusTypes.POSTED)) throw new PitchError("Job eithe hasn't been posted yet or has expired");
        if(job){
            this._job = job;
            this.jobId = job.jobId;
            this.orgId = job.orgId;
            this.posterId = job.posterId;
        }
    }

    static getPKPrefix(){
        return "USER#";
    }
    static getSKPrefix(){
        return "INVITE#";
    }
    getSKPrefix(){
        return "INVITE#";
    }
    getPK(){
        if(!this.userId) throw new PitchError("userId is not set");
        return Invite.getPKPrefix() + this.userId;
    }
    getSK(){
        if(!this.inviteId) throw new PitchError("inviteId is not set");
        return Invite.getSKPrefix() + this.inviteId;
    }
    getParentKey(){
        return [User.generateKey({userId: this.userId}), Job.generateKey({orgId: this.orgId, jobId: this.jobId})];
    }
    getGlobalIndices(){
        if(!this.jobId) throw new PitchError("jobId is not set");
        const indices = {};
        indices.GSI1PK = "ORG#" + this.orgId + "#JOB#" + this.jobId;
        indices.GSI1SK = "USER#" + this.userId;
        if(this.application && this.application.status === Application.appStatusTypes.SUBMITTED){
            indices.GSI2PK = "JOB#" + this.jobId;
            indices.GSI2SK = "DECISIONSTATUS#" + (this.application.decisionStatus || 0) + "#INVITE#" + this.inviteId;
        }
        indices.GSI3PK = "USER#" + this.userId;
        indices.GSI3SK = "INVITECLOSINGDATE#" + this.deadline;
        return indices;
    }
    static getIndexPermissionKey(indexNumber, item){
        switch(indexNumber){
            case 1:
            case 2:
                return {PK: "ORG#" + item.orgId, SK: "JOB#" + item.jobId};
            case 3:
                return Invite.generateKey({userId: item.userId, inviteId: item.inviteId});
            default:
                throw new PitchError("Invite does not have index " + indexNumber);
        }
    }
    getIndexPermissionKey(indexNumber){
        return Invite.getIndexPermissionKey(indexNumber, this);
    }

    static inviteStatusTypes = {
        UNOPENED: 0,
        OPENED: 1,
        APP_CREATED: 2,
        APP_PITCH_ADDED: 3,
        APP_SUBMITTED: 4,
        DELETED: 5
    };

    static inviteStatusTypesNames = {
        0: "Unopened",
        1: "Opened",
        2: "Application created",
        3: "Pitch added",
        4: "Application submitted",
        5: "Invite deleted"
    };

    static inviteStatusTypesStyles = {
        0: standardStyles.UNOPENED,
        1: standardStyles.OPENED,
        2: standardStyles.OPENED,
        3: standardStyles.SUBMITTED
    };
    static generateKey({userId, inviteId}){
        return {
            PK: Invite.getPKPrefix() + userId,
            SK: Invite.getSKPrefix() + inviteId
        }
    }
    static generatePK({userId}){
        return Invite.getPKPrefix() + userId;
    }
    static generateGSIPK(indexNumber, key){
        switch(indexNumber){
            case 1:
                return "ORG#" + key.orgId + "#JOB#" + key.jobId;
            case 2:
                return "JOB#" + key.jobId;
            case 3:
                return "USER#" + key.userId;
            default:
                throw new PitchError("Invite does not have index " + indexNumber);
        }
    }
    static generateGSISK(indexNumber, key){
        switch(indexNumber){
            case 1:
                return "USER#" + ((key && key.userId) ? key.userId : "");
            case 2:
                return "DECISIONSTATUS#" + ((key && key.decisionStatus) ? key.decisionStatus : "");
            case 3:
                return "INVITECLOSINGDATE#" + ((key && key.date) ? key.date : "");
            default:
                throw new PitchError("Invite does not have index " + indexNumber);
        }
    }
    /**
     * @param {Job} job
     */
    set job(job){
        if(!(job instanceof Job)){
            throw new PitchError("Tried to set job, but object not an job object");
        }
        this._job = job;
        if(job.closingDate) this.deadline = job.closingDate;
        this.markFieldDirty("_job");
    }
    get job(){
        return this._job;
    }
    /**
     * @param {Application} application
     */
    set application(application){
        if(!(application instanceof Application)){
            throw new PitchError("Tried to set application, but object not an application object");
        }
        this._application = application;
        this.markFieldDirty("_application");
    }
    get application(){
        return this._application;
    }
    /**
     * @param {number} deadline
     */
    set deadline(deadline){
        this._deadline = deadline;
        this.markFieldDirty("_deadline");
    }
    get deadline(){
        return this._deadline;
    }
    hasDeadlinePassed(){
        return this._deadline < DateTime.now().toMillis();
    }
    static getClassName(){
        return "Invite";
    }
    static getColumnsForExport(){
        return [
            "decisionStatus",
            ...ApplicantDetails.getColumnsForExport()
        ]
    }
    getObjectForExport(){
        return {
            decisionStatus: Application.applicationStatusTypesNames[this.application.decisionStatus],
            ...this.application.applicantDetails.getFlatObject()
        }
    }
    set decisionStatus(status){
        if(!this.application) throw new PitchError("Can't set decision status as application not started");
        //If no status change then exit
        if(this.application.decisionStatus === status) return;
        //Check if parent counts already have a status set. If they do, subtract 1. If they don't and status has been set, then set to -1.
        if(this.parentCountChanges["decision" + this._application._decisionStatus]){
            this.parentCountChanges["decision" + this._application._decisionStatus] -= 1;
        }else if(this.status || this.status === 0){
            this.parentCountChanges["decision" + this._application._decisionStatus] = -1;
        }
        //Set status
        this.application.decisionStatus = status;
        //Now increase or set counts
        if(this.parentCountChanges["decision" + this._application._decisionStatus]){
            this.parentCountChanges["decision" + this._application._decisionStatus] += 1;
        }else{
            this.parentCountChanges["decision" + this._application._decisionStatus] = 1;
        }
        this.markFieldDirty("_application");
    }
    get decisionStatus(){
        if(!this.application) throw new PitchError("Can't get decision status as application not started");
        return this.application.decisionStatus;
    }
    set applicantIndex(applicantIndex){
        this.application.applicantIndex = applicantIndex;
        this.markFieldDirty("_application");
    }
    get applicantIndex(){
        return this.application.applicantIndex;
    }
}

export class Link extends BaseDynamoClass {
    constructor(linkId, org, job, type, userId, checkKey, permissions){
        super();
        this.linkId = linkId;
        if(job){
            this.jobId = job.jobId;
            this.job = job;
            this.orgId = job.orgId;
            this.posterId = job.posterId;
        }
        if(type || type === 0){
            this.type = type;
        }
        if(org){
            this.orgId = org.orgId;
            this.org = org;
        }
        if(userId){
            this.userId = userId;
        }
        if(checkKey){
            this.checkKey = checkKey;
        }
        if(permissions){
            this.permissions = permissions;
        }
    }
    static linkTypes = {
        INVITE: 0,
        APPLICANT_LIST: 1,
        JOIN_ACCOUNT: 2
    }
    static getPKPrefix(){
        return "ORG#";
    }
    static getSKPrefix({type, jobId = null}){
        return "LINKTYPE#" + ((type || type === 0) ? type : "") + (jobId ? `#JOB#${jobId}` : "");
    }
    getSKPrefix({type, jobId = null}){
        return Link.getSKPrefix({type, jobId});
    }
    static isPermissionCheckNeeded(permission){
        return permission > Permission.permissionTypes.OWNER;
    }
    isPermissionCheckNeeded(permission){
        return permission > Permission.permissionTypes.OWNER;
    }
    static generateKey({orgId, jobId = null, linkId, type}){
        return {
            PK: Link.generatePK({orgId}),
            SK: Link.generateSK({linkId, type, jobId})
        }
    }
    static generatePK({orgId}){
        return Link.getPKPrefix() + orgId;
    }
    static generateSK({linkId, type, jobId = null}){
        return Link.getSKPrefix({type, jobId}) + "#LINK#" + linkId;
    }
    getParentKey(){
        switch(this.type){
            case Link.linkTypes.INVITE:
            case Link.linkTypes.APPLICANT_LIST:
                return Job.generateKey({orgId: this.orgId, jobId: this.jobId});
            case Link.linkTypes.JOIN_ACCOUNT:
            default:
                return Organisation.generateKey({orgId: this.orgId});
        }
    }
    getGlobalIndices(){
        return {
            GSI1PK: "LINK#" + this.linkId,
            GSI1SK: "LINKTYPE#" + this.type
        };
    }
    static generateGSIPK(indexNumber, key){
        if(indexNumber === 1){
            return "LINK#" + key.linkId;
        }
        throw new PitchError("Link does not have GSI" + indexNumber);
    }
    getPK(){
        if(!this.orgId) throw new PitchError("orgId is not set");
        return Link.getPKPrefix() + this.orgId;
    }
    getSK(){
        return Link.getSKPrefix({type: this.type, jobId: this.jobId}) + "#LINK#" + this.linkId;
    }
    /**
     * @param {Job} job
     */
    set job(job){
        if(!(job instanceof Job)){
            throw new PitchError("Tried to set job, but object not a job object");
        }
        this._job = job;
        if(job.closingDate) this.deadline = job.closingDate;
        this.markFieldDirty("_job");
    }
    get job(){
        return this._job;
    }
    /**
     * @param {number} deadline
     */
    set deadline(deadline){
        this._deadline = deadline;
        this.markFieldDirty("_deadline");
    }
    get deadline(){
        return this._deadline;
    }
    static getClassName(){
        return "Link";
    }
}

export class Interaction extends BaseDynamoClass {
    static interactionTypes = {
        VIEW: "VIEW",
        LIKE: "LIKE",
        DECISION: "DECISION",
        RATING: "RATING",
        COMMENT: "COMMENT"
    }
    static interactionTypeOrder = {
        VIEW: 10,
        LIKE: 20,
        COMMENT: 50,
        RATING: 80,
        DECISION: 90
    }
    constructor(parentKey, interactionType, interactionId){
        super();
        this.parentKey = {};
        if(parentKey){
            this.parentKey.parentKey = parentKey.PK;
            this.parentKey.childKey = parentKey.SK;
        }
        if(interactionType && interactionId) this.interactionType = interactionType; this.interactionId = interactionId;
    }
    static getClassName(){
        return "Interaction";
    }
    static getPKPrefix({parentType}){
        return parentType + "#";
    }
    static getSKPrefix({interactionType}){
        return Interaction.interactionTypeOrder[interactionType] + interactionType + "#";
    }
    static isPermissionCheckNeeded(){
        return false
    }
    isPermissionCheckNeeded(){
        return false
    }
    getPK(){
        if(!this.parentKey) throw new PitchError("PK is not set");
        return this.parentKey.parentKey + "#" + this.parentKey.childKey;
    }
    getSK(){
        if(!this.interactionId || !this.interactionType) throw new PitchError("SK is not set");
        return Interaction.getSKPrefix({interactionType: this.interactionType}) + this.interactionId;
    }
    setParentKey(parentKey){
        if(!this.parentKey) this.parentKey = {};
        this.parentKey.parentKey = parentKey.PK;
        this.parentKey.childKey = parentKey.SK;
    }
    getParentKey(){
        return {PK: this.parentKey.parentKey, SK: this.parentKey.childKey};
    }
    //TODO these two methods smell bad
    getParentTableName(){
        return process.env.primaryTableName;
    }
    static getParentTableName(){
        return process.env.primaryTableName;
    }
    static generateKey({parentKey, interactionType, interactionId}){
        return {
            PK: parentKey.PK + "#" + parentKey.SK,
            SK: Interaction.getSKPrefix({interactionType}) + interactionId
        }
    }
    static generatePK({parentKey}){
        return parentKey.PK + "#" + parentKey.SK;
    }
    static generateGSIPK(indexNumber, key){
        if(indexNumber === 1){
            return key.orgId ? "ORG#" + key.orgId + "#USER#" + key.userId : "USER#" + key.userId
        }
        throw new PitchError("Interaction does not have other indices apart from GSI" + indexNumber);
    }
    static generateGSISK(indexNumber, key){
        if(indexNumber === 1){
            if(key.parentKey){
                return Interaction.interactionTypeOrder[key.interactionType] + key.interactionType + "#" + key.parentKey.PK + "#" + key.parentKey.SK;
            }
            return Interaction.interactionTypeOrder[key.interactionType] + key.interactionType + "#";
        }
        throw new PitchError("Interaction does not have other indices apart from GSI" + indexNumber);
    }
    getGlobalIndices(){
        return {
            GSI1PK: this.orgId ? "ORG#" + this.orgId + "#USER#" + this.userId  : "USER#" + this.userId,
            GSI1SK: Interaction.getSKPrefix({interactionType: this.interactionType}) + this.getPK()
        };
    }
    /**
     * @param {number} rating
     */
    set rating(rating){
        this._rating = rating;
        this.markFieldDirty("_rating");
    }
    get rating(){
        return this._rating;
    }
    /**
     * @param {string} comment
     */
    set comment(comment){
        this._comment = comment;
        this.markFieldDirty("_comment");
    }
    get comment(){
        return this._comment;
    }
}

export const getModelSerializer = () => new Serializer([Location, Subscription, Subscriptions, Organisation, OrganisationUser, User, Job, JobQuestion, Invite, Link, Application, Pitch, Answer, Interaction, ApplicantDetails, SearchResult, Permission]);