import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {Amplify, Analytics, Auth} from "aws-amplify";
import {appConfig} from './libs/shared/app-config';
import { initSentry } from './libs/sentryLib';

initSentry();

Amplify.configure({
  Storage: {
    region: appConfig.s3.REGION,
    bucket: appConfig.s3.BUCKET,
    identityPoolId: appConfig.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "lambda",
        endpoint: appConfig.apiGateway.URL,
        region: appConfig.apiGateway.REGION
      },
    ]
  }
});

Auth.configure({
  mandatorySignIn: false,
  region: appConfig.cognito.REGION,
  userPoolId: appConfig.cognito.USER_POOL_ID,
  identityPoolId: appConfig.cognito.IDENTITY_POOL_ID,
  userPoolWebClientId: appConfig.cognito.APP_CLIENT_ID
});

Analytics.configure({
  disabled: false,
  autoSessionRecord: true,
  AWSPinpoint: {
    appId: appConfig.pinpoint.APP_ID,
    region: appConfig.pinpoint.REGION,
    mandatorySignIn: true
  }
});

Analytics.autoTrack('pageView', {
  enable: true,
  //Single Page App
  type: 'SPA',
  // OPTIONAL, to get the current page url
  getUrl: () => {
      // the default function
      return window.location.origin + window.location.pathname;
  }
});


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
