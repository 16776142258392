import Serializer, {SerializableError} from "./utils"

export class PitchError extends SerializableError {
    constructor(message, cause){
        if(cause){
            if(message){
                super(message, {cause});
            }else{
                super("Unknown error", {cause});
            }
            this.cause = cause;
        }else if(message){
            super(message);
        }else{
            super("Unknown error");
        }
    }
    static getClassName(){
        return "PitchError";
    }
}

export class PitchUserError extends PitchError {
    static getClassName(){
        return "PitchUserError";
    }
}

export class PitchUserNotConfirmedError extends PitchError {
    static getClassName(){
        return "PitchUserNotConfirmedError";
    }
}

export class PitchNoCurrentUserError extends PitchError {
    static getClassName(){
        return "PitchNoCurrentUserError";
    }
}

export class PitchNotOrganisationUserError extends PitchUserError {
    static getClassName(){
        return "PitchNotOrganisationUserError";
    }
}

export class PitchNoPermissionError extends PitchUserError {
    static getClassName(){
        return "PitchNoPermissionn";
    }
}

export class PitchRecordNotFoundError extends PitchError {
    static getClassName(){
        return "PitchRecordNotFoundError";
    }
}

export class PitchDatabaseError extends PitchError {
    static getClassName(){
        return "PitchDatabaseError";
    }
}

export class PitchPasswordChangeRequired extends PitchError {
    static getClassName(){
        return "PitchPasswordChangeRequired";
    }
}

export class PitchSubscriptionLimitReached extends PitchUserError {
    static getClassName(){
        return "PitchSubscriptionLimitReached"
    }
}

export const getErrorSerializer = () => new Serializer([PitchError, PitchUserError, PitchUserNotConfirmedError, PitchNoCurrentUserError, PitchNotOrganisationUserError, PitchRecordNotFoundError, PitchPasswordChangeRequired, PitchDatabaseError, PitchNoPermissionError, PitchSubscriptionLimitReached]);