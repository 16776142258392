import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { DashCircle, ExclamationDiamond } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";

export const defaultToolips = {
  NO_PERMISSION: "You do not have permission to perform this action",
  APPLICANT_LIMIT: "This application was above your monthly limit. Upgrade your subscription to view."
}

export default function PitchButton({
  isLoading,
  onClick,
  className = "",
  disabled = false,
  tooltip = null,
  href = null, 
  ButtonComponent = Button,
  isWarning = false,
  warningTooltip = null,
  warningOnClick = null,
  disabledTooltip = defaultToolips.NO_PERMISSION,
  overlayComponent = null,
  block = false,
  ...props
}) {

  function renderButton({isLoading, className, disabled, style, href, onClick, ...props}){
    return(
      <div className={block ? "d-grid" : ""}>
        <ButtonComponent
          disabled={disabled || isLoading}
          style={style}
          href={href}
          onClick={onClick}
          className={`btn-loading justify-content-center ${className}`}
          {...props}
        >
          {isLoading && <BsArrowRepeat className="spinning" />}
          {disabled && (<DashCircle size="14" className="mx-2"/>)}
          {isWarning && <ExclamationDiamond size="14" className="mx-2"/>}
          {props.children}{disabled}
        </ButtonComponent>
      </div>
    );
  }

  return (
    <>
      {disabledTooltip && disabled ? (
          <OverlayTrigger overlay={overlayComponent ? overlayComponent : <Tooltip>{disabledTooltip}</Tooltip>}>
              <div className={`mb-2 ${block ? "d-grid" : ""}`} style={{display: "inline-block"}}>
                {renderButton({isLoading, className, disabled, href, onClick: (isWarning ? warningOnClick : onClick), style: {...props.style, pointerEvents: "none"}, ...props})}
              </div>
          </OverlayTrigger>
      ) : tooltip ? (
          <OverlayTrigger overlay={overlayComponent ? overlayComponent : <Tooltip>{tooltip}</Tooltip>}>
            {renderButton({isLoading, className, disabled, href, onClick: (isWarning ? warningOnClick : onClick), ...props})}
          </OverlayTrigger>
        ) : renderButton({isLoading, className, disabled, href, onClick: (isWarning ? warningOnClick : onClick),  ...props})
      }
    </>
  );
}