export class Serializable{
    static getClassName(){
        throw new Error("getClassName must be implemented for serializable class");
    }
    get className(){
        return this.constructor.getClassName();
    }
    // eslint-disable-next-line class-methods-use-this
    set className(className){
        return;
    }
    // eslint-disable-next-line class-methods-use-this
    getIgnoreFields(){
        return [];
    }
}

export class SerializableError extends Error {
    static getClassName(){
        throw new Error("getClassName must be implemented for serializable class");
    }
    get className(){
        return this.constructor.getClassName();
    }
    // eslint-disable-next-line class-methods-use-this
    set className(className){
        return;
    }
    // eslint-disable-next-line class-methods-use-this
    getIgnoreFields(){
        return [];
    }
}

export default class Serializer{
    constructor(types){
        this.standardTypes = [Object, Array, Error, Date];
        this.types = [...this.standardTypes];
        if(types) this.types.push(...types);
    }
    serialize(object, options = {}) {
        const stringify = (options && options.stringify) || false;
        const anonymiseFirstLevel = (options && options.anonymiseFirstLevel) || false;
        const viewIndex = (options && options.viewIndex) || 100;
        const secondLevelIgnoreFields = (options && options.secondLevelIgnoreFields) ? [...options.secondLevelIgnoreFields] : [];
        const ignoreFields = (options && options.ignoreFields) ? [...options.ignoreFields] : [];
        const ignoreUnrecognisedClasses = (options && options.ignoreUnrecognisedClasses) ? options.ignoreUnrecognisedClasses : false;

        const primitive = object !== Object(object);
        const standardObject = this.standardTypes.includes(object.constructor);
        const serializableObject = (object instanceof Serializable || object instanceof SerializableError) && this.types.includes(object.constructor);
        if(serializableObject && object.getIgnoreFields) ignoreFields.push(...object.getIgnoreFields(viewIndex));
        if(!primitive && !standardObject && !serializableObject && !ignoreUnrecognisedClasses) throw new Error("type  '" + object.constructor.name + "' not serializable.");

        let tempObj = {};
        //If primitive, return itself
        if(!primitive){
            for(const name of Object.getOwnPropertyNames(object)){
                if(ignoreFields.includes(name)) continue;
                if(object[name] !== null && object[name] !== undefined && object[name].constructor === Array){
                    tempObj[name] = ['Array', []];
                    for(let x=0; x<object[name].length; x++){
                        tempObj[name][1][x] = this.serialize(object[name][x], {stringify: false, anonymiseFirstLevel: false, ignoreFields: [...ignoreFields, ...secondLevelIgnoreFields]});
                    }
                }else if(object[name] !== null && object[name] !== undefined && (object[name].constructor === Function || object[name] === Object(object[name]))){
                    tempObj[name] = this.serialize(object[name], {stringify: false, anonymiseFirstLevel: false, ignoreFields: [...ignoreFields, ...secondLevelIgnoreFields]});
                }else{
                    tempObj[name] = object[name];
                }
            }
            if(!anonymiseFirstLevel){
                tempObj = serializableObject ? [object.constructor.getClassName(), tempObj] : !standardObject ? [Object.name, tempObj] : [object.constructor.name, tempObj];
            }
        }else{
            tempObj = object;
        }
        if(stringify){
            return JSON.stringify(tempObj);
        }
        return tempObj;
    }
    deserialize(jstring, options = {}) {
        const parse = (options && options.parse) || false;
        const anonymousFirstLevel = (options && options.anonymousFirstLevel) || false;
        const firstLevelClass = (options && options.firstLevelClass) || false;
        const viewIndex = (options && options.viewIndex) || 100;
        const ignoreFields = (options && options.ignoreFields) ? [...options.ignoreFields] : [];
        let array;
        let className;
        let object;

        if(anonymousFirstLevel){
            if(!firstLevelClass) throw new Error("Anonymous first level, but no class name specified");
            className = Serializer.getClassName(firstLevelClass);
            if(parse){
                object = JSON.parse(jstring);
            }else{
                object = {...jstring};
            }
        }else{
            if(parse){
                array = JSON.parse(jstring);
            }else{
                array = [...jstring];
            }
            className = array[0];
            object = array[1];
        }
        const idx = this.getTypeIndex(className);
        if (idx === -1) throw new Error("type  '" + className + "' not deserializable");
        const classObject = new this.types[idx]();
        if(classObject instanceof Serializable && classObject.getIgnoreFields) ignoreFields.push(...classObject.getIgnoreFields(viewIndex));
        if(this.types[idx] === Array){
            for(let x = 0; x < object.length; x++){
                if(object[x].constructor === Array){
                    classObject[x] = this.deserialize(object[x], {ignoreFields});
                }else{
                    classObject[x] = object[x];
                }
            }
        }else{
            for(const name of Object.getOwnPropertyNames(object)){
                if(ignoreFields.includes(name)) continue;
                if(object[name] !== null && object[name] !== undefined && object[name].constructor === Array){
                    classObject[name] = this.deserialize(object[name], {ignoreFields});
                }else{
                    classObject[name] = object[name];
                }
            }
        }
        return classObject;
    }
    isTypeSupported(type){
        return this.getTypeIndex(type) !== -1;
    }
    isInstanceSupported(obj){
        return ((obj instanceof Function || obj instanceof Array || obj instanceof Object) || ((obj instanceof Serializable || obj instanceof SerializableError) && this.getTypeIndex(obj.constructor.getClassName()) !== -1) || (obj !== Object(obj)));
    }
    getTypeIndex(className){
        return this.types.findIndex((e) => {
            if(e.prototype instanceof Serializable || e.prototype instanceof SerializableError) return e.getClassName() === className;
            if(e.constructor === Function || e.constructor === Array || e.constructor === Object || e.constructor === Error) return e.name === className;
            throw new Error("Type in index isn't serializable. Serializer hasn't been set up correctly: " + e.constructor.name);
        });
    }
    getClass(className){
        return this.types[this.getTypeIndex(className)];
    }
    static getClassName(clazz){
        if(clazz.prototype instanceof Serializable || clazz.prototype instanceof SerializableError) return clazz.getClassName();
        if(clazz.constructor === Function || clazz.constructor === Array || clazz.constructor === Object || clazz.constructor === Error) return clazz.name;
        throw new Error("Class isn't supported: " + clazz.constructor.name);
    }
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
}

export function deep(value){
    if (typeof value !== 'object' || value === null) {
      return value
    }
    if (Array.isArray(value)) {
      return deepArray(value)
    }
    return deepObject(value)
}

function deepObject(source) {
    const result = {}
    Object.keys(source).forEach((key) => {
      const value = source[key]
      result[key] = deep(value)
    }, {})
    return result;
}

function deepArray(collection) {
    return collection.map((value) => deep(value));
}

Object.filter = function( obj, predicate) {
    const result = {};
	let key;

    for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) && predicate(obj[key])) {
            result[key] = obj[key];
        }
    }

    return result;
};

export function getFileExtension(fileName){
    const a = fileName.split(".");
    if( a.length === 1 || ( a[0] === "" && a.length === 2 ) ) {
        return "";
    }
    return a.pop();
}

export function dedupeObjectArray(data, key){
    return data.reduce( (result, current) => {
      if(!result[current[key]]){
        result[current[key]] = current;
        result[current[key]].count = 1;
      } else {
        result[current[key]].count += 1;
      }
      return result;
    }, {});
}

export function dedupeArrayOfArrays(data, keyColumnIndex){
    return data.reduce((result, current) => {
        const keyName = current[keyColumnIndex];
        if(!result[keyName]){
            result[keyName] = current;
            result[keyName].count = 1;
        }else{
            result[keyName].count += 1;
        }
        return result;
    }, {});
}

Number.preciseRound = (val, decimals = 2) => +(Math.round(+(val + "e+" + decimals))  + "e-" + decimals);

Number.meanAverage = (numerator, denominator, decimals = 2, NaNReplacement = 0) => {
    if(denominator === 0) return NaNReplacement;
    const average = numerator / denominator;
    return Number.preciseRound(average, decimals);
}

Object.filterByKey = (obj, predicate) => Object.keys(obj)
          .filter( key => predicate(key) )
          .reduce( (res, key) => ({...res, [key]: obj[key]}), {} );