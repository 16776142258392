import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {logLevel} from "../libs/shared/enums";

export const environment = process.env.REACT_APP_STAGE === "prod" ? "production" : process.env.REACT_APP_STAGE === "staging" ? "staging" : "dev";
export const isLocal = process.env.NODE_ENV === "development" && window.location.host.startsWith("localhost");
export const isForceShowError = 
(new URL(window.location)).searchParams.get("showErrors");
let errorCount = 0;

export function initSentry() {
  if (isLocal) {
    return;
  }
  Sentry.init({
    dsn: "https://1e5561e9e511479b88f0f6cc8e64e4c6@o684354.ingest.sentry.io/5771506",
    integrations: [new Integrations.BrowserTracing()],
    environment,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};

export function logError(error, errorInfo = null) {

  if (isLocal || errorCount > 50) {
    console.error(error, errorCount);
    return;
  }

  Sentry.withScope((scope) => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
};

export function logMessage(message, level){
  if(isLocal){
    if(level === logLevel.DEBUG) console.debug(message);
    if(level === logLevel.INFO) console.info(message);
    if(level === logLevel.WARN) console.warn(message);
  }
}
