import BackButton from "../buttons/BackButton";
import StandardPageContainer from "./StandardPageContainer";

export default function PlaceholderPage({children}){
    return(
        <StandardPageContainer className="mt-3">
            <BackButton text="Go back"/>
            {children}
        </StandardPageContainer>
    )
}