import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router";
import { createStripePortalSession, getOrganisation, getUserPermissions, logAnonMetrics, logAnonView, logDecision, logView, updateInteraction } from "../api/lambda_api";
import { storeAnonView } from "../api/localstorage_api";
import { Organisation, User } from "../libs/shared/model";
import { useErrorContext, useInfoContext } from "./contextLib";
import logger from "./errorLib";
import { PitchError } from "./shared/exceptions";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });
    }
  ];
}

const bootstrapSizing = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export function compareBootstrapSizes(bs1, bs2){
  const idx1 = Object.keys(bootstrapSizing).indexOf(bs1);
  const idx2 = Object.keys(bootstrapSizing).indexOf(bs2);
  if(idx1 === -1 || idx2 === -1) throw new PitchError("Invalid boostrap size: " + bs1 + " " + bs2);
  if(idx1 > idx2) return 1;
  if(idx1 === idx2) return 0;
  if(idx1 < idx2) return -1;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  let bootstrapSize = "xs";
  if(width >= bootstrapSizing.xl){
    bootstrapSize = "xl";
  }else if(width >= bootstrapSizing.lg){
    bootstrapSize = "lg"
  }else if(width >= bootstrapSizing.md){
    bootstrapSize = "md";
  }else if(width >= bootstrapSizing.sm){
    bootstrapSize = "sm"
  }
  return {
    width,
    height,
    bootstrapSize
  };
}

function getDimensions(ref){
  return {
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight
  };
}

export function useDimensions(ref, dependencies = null) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      if(ref) setDimensions(getDimensions(ref));
    }

    if (ref && ref.current) {
      setDimensions(getDimensions(ref));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  useEffect(() => {
    if(ref && ref.current){
      setDimensions(getDimensions(ref));
    }
  },[dependencies, ref]);

  return {
    window: windowDimensions,
    container: dimensions
  }
}

export function useCalculatedDimensions(componentRef, originalHeight, originalWidth, dependencies = null) {
  const {container, window} = useDimensions(componentRef, dependencies);
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [maxHeight, setMaxHeight] = useState();
  const [maxWidth, setMaxWidth] = useState();

  const calculateDimensions = useCallback((originalWidth, originalHeight) => {
    const dimensions = {};
    const containerRatio = container.width / originalWidth;
    const bootstrapHeight = decideHeight(window.bootstrapSize);
    dimensions.maxHeight = bootstrapHeight;
    dimensions.maxWidth = bootstrapHeight * (originalWidth / originalHeight);
    if(originalHeight * containerRatio > bootstrapHeight){
        dimensions.height = dimensions.maxHeight;
        dimensions.width = dimensions.maxWidth;
    }else{
        dimensions.height = originalHeight * containerRatio;
        dimensions.width = dimensions.height * (originalWidth / originalHeight);
    }
    return dimensions;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container, window.bootstrapSize]);

  useEffect(() => {
    const dimensions = calculateDimensions(originalWidth || container.width, originalHeight || container.height);
    setHeight(dimensions.height);
    setWidth(dimensions.width);
    setMaxHeight(dimensions.maxHeight);
    setMaxWidth(dimensions.maxWidth);
  },[container, calculateDimensions, originalWidth, originalHeight, dependencies]);

  function decideHeight(bootstrapSize){
    switch (bootstrapSize){
        case "xs":
            return 400;
        default:
            return decideWidth(bootstrapSize) / 1.777777777;
    }
  }

  function decideWidth(bootstrapSize){
    switch (bootstrapSize){
        case "xxl":
            return 1320;
        case "xl":
            return 1140;
        case "lg":
            return 960;
        case "md":
            return 720;
        case "sm":
            return 540;
        case "xs":
            return 0;
        default:
            return 0;
    }
  }

  return {
    height,
    width,
    maxHeight,
    maxWidth,
    calculateDimensions
  }
}

export function useScrollStatus(){
  const [scrollStarted, setScrollStarted] = useState(false);
  const [bottomScrollLimitReached, setBottomScrollLimitReached] = useState(false);
  const [scrollY, setScrollY] = useState(window.scrollY);
  useEffect(() => {
    function handleScroll(event) {
      if(!scrollStarted) setScrollStarted(true);
      const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
      setScrollY(window.scrollY);
      setBottomScrollLimitReached(bottom);
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return {
    bottom: bottomScrollLimitReached,
    scrollY,
    scrollStarted
  }
}

//https://stackoverflow.com/questions/58341787/intersectionobserver-with-react-hooks/67826055#67826055
export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsOnScreen(entry.isIntersecting)
    );
  }, []);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [ref]);

  return isOnScreen;
}

export function isUserSignUpComplete(authenticated, user){
  if(!authenticated) return false;
  if(!user) return false;
  if(!user.status) return false;
  switch (user.status){
      case User.userRegistrationStatus.UNCONFIRMED:
      case User.userRegistrationStatus.CONFIRMED:
          return false;
      case User.userRegistrationStatus.COMPLETED:
          return true;
      default:
          return false;
  }
}

export function isOrganisationSignUpComplete(authenticated, user, org){
  if(!authenticated) return false;
  if(!user || !org) return false;
  if(!user.status || !org.status) return false;
  switch (user.status){
      case User.userRegistrationStatus.UNCONFIRMED:
      case User.userRegistrationStatus.CONFIRMED:
          return false;
      case User.userRegistrationStatus.COMPLETED:
          return org.status === Organisation.organisationRegistrationStatus.COMPLETED;
      default:
          return false;
  }
}

export function getFullUrl(path, queryString){
  const host = window.location.host;
  const protocol = window.location.protocol;
  let url = protocol + "//" + host + "/" + path;
  url = queryString ? url + "?" + queryString : url;
  return url;
}

export function useInterval(callback, delay, id = ""){
  const savedCallback = useRef();
  const [count, setCount] = useState(0);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick(){
      savedCallback.current();
      setCount(prevCount => prevCount + 1);
    }
    if(delay !== null){
      const id = setInterval(tick, delay)
      logger.debug("Interval set with id: " + id);
      return () => {
        clearInterval(id);
      };
    }
  }, [delay]);

  return {
    ["count" + id]: count
  }
}

export function useScript(url, condition = true){
  useEffect(() => {
    let script;
    if(condition){
      script = document.createElement('script');

      script.src = url;
      script.async = true;

      document.head.appendChild(script);
    }

    return () => {
      if(script) document.head.removeChild(script);
    }
  }, [url, condition]);
};

//https://stackoverflow.com/questions/53451584/is-it-possible-to-share-states-between-components-using-the-usestate-hook-in-r
function makeObservable(target) {
  let listeners = [];
  let value = target;

  function get() {
    return value;
  }

  function set(newValue) {
    if (value === newValue) return;
    value = newValue;
    listeners.forEach((l) => l(value));
  }

  function subscribe(listenerFunc) {
    listeners.push(listenerFunc);
    return () => unsubscribe(listenerFunc); // will be used inside React.useEffect
  }

  function unsubscribe(listenerFunc) {
    listeners = listeners.filter((l) => l !== listenerFunc);
  }

  return {
    get,
    set,
    subscribe,
  };
}

const permissionsStore = makeObservable();

export function usePermissions() {
  const [permissionsState, setPermissionsState] = useState(permissionsStore.get());

  useEffect(() => {
    return permissionsStore.subscribe(setPermissionsState);
  }, []);

  const getPermission = useCallback((Key, permission) => {
    if(permissionsState){
      const filteredPermissions = permissionsState.filter(perm => (perm.isWildcard() || (perm.getEntityKey().PK === Key.PK && perm.getEntityKey().SK === Key.SK)) && perm.permission >= permission);
      filteredPermissions.sort((perm1, perm2) => perm2.permission - perm1.permission);
      return filteredPermissions[0];
    }
    return null;
  },[permissionsState]);

  const setPermissions = (permissions) => {
    permissionsStore.set(permissions);
  }

  const loadPermissions = useCallback(async(orgId) => {
    const permissions = await getUserPermissions(orgId);
    setPermissions(permissions.items);
  },[]);

  return {
    permissions: permissionsState,
    getPermission,
    setPermissions,
    loadPermissions
  }
}

const organisationStore = makeObservable();

export function useOrganisation() {
  const [organisation, setOrgState] = useState(organisationStore.get());

  useEffect(() => {
    return organisationStore.subscribe(setOrgState);
  }, []);

  const setOrganisation = (org) => {
    organisationStore.set(org);
  }

  const setOrganisationById = async(orgId) => {
    const org = await getOrganisation(orgId);
    organisationStore.set(org);
  }

  const reloadOrganisation = async() => {
    const orgReloaded = await getOrganisation(organisation.orgId);
    organisationStore.set(orgReloaded);
  }

  return {
    organisation,
    setOrganisation,
    setOrganisationById,
    reloadOrganisation
  }
}

const userStore = makeObservable();

export function useUser() {
  const [user, setUserState] = useState(userStore.get());

  useEffect(() => {
    return userStore.subscribe(setUserState);
  }, []);

  const setUser = (user) => {
    userStore.set(user);
  }

  return {
    user,
    setUser
  }
}

export function useLogView(user, itemKey, orgId, start = true) {

  const [isStarted, setIsStarted] = useState(false);
  const [view, setView] = useState(null);

  const startView = useCallback(async(initialMetrics = {}) => {
    if(itemKey){
      logger.debug("Starting view");
      setIsStarted(true);
      if(user){
        const viewUpdated = await logView(user, itemKey, initialMetrics, orgId);
        setView(viewUpdated);
      }else{
        const firstView = storeAnonView(itemKey);
        await logAnonView(itemKey, initialMetrics, firstView);
        setView({});
      }
    }
  },[itemKey, orgId, user]);

  const endView = () => {
    setIsStarted(false);
  }

  useEffect(() => {
    if(start && !isStarted && itemKey) startView();
  },[itemKey, start, isStarted, startView]);

  const incrementMetrics = async(metrics) => {
    if(user){
      //Mark clean in case any state changes are still processing.
      view.markClean();
      view.changeCounts(metrics, true);
      const viewUpdated = await updateInteraction(view, orgId);
      setView(viewUpdated);
    }else{
      await logAnonMetrics(itemKey, metrics);
      setView({});
    }
  }
  return {
    startView,
    endView,
    view,
    incrementMetrics
  }
}

export function useLogDecision(user, itemKey, viewerOrgId) {

  const [decision, setDecision] = useState(null);

  const makeDecision = useCallback(async(decision) => {
    logger.debug("Logging decision");
    if(itemKey && user){
      const intUpdated = await logDecision(viewerOrgId, user, itemKey, decision);
      setDecision(intUpdated);
    }
  },[itemKey, viewerOrgId, user]);

  return {
    makeDecision,
    decision
  }
}

export function useRedirectToSubscriptions(){
  const {setUserMessage} = useInfoContext();
  const {setError} = useErrorContext();
  const {organisation} = useOrganisation();
  const navigate = useNavigate();

  async function redirect(e){
    if(e) e.preventDefault();
    setUserMessage({
        title: "Subscription limit reached",
        body: "You've reached the limit of your subscription. To perform this action increase your subscription level.",
        buttonText: (organisation.hasActiveSubscription() ? "Manage subscription" : "Upgrade susbcription"),
        cancelButtonText: "Cancel",
        callback: async() => {
          if(!organisation.hasActiveSubscription() || organisation.subscription.status === 'free') {
            navigate('/select_subscription');
          }else{
            try{
              const completionPath = "org_settings";
              const session = await createStripePortalSession(organisation.subscription.customerId, completionPath);
              window.location.assign(session.stripeUrl);
            }catch(e){
              setError(e);
            }
          }
        }
    });
  }
  return {
    redirect
  };
}

export function usePageTitle(title){
  useEffect(() => {
      document.title = (title && title !== "title") ? title + " | Pitchli" : "Pitchli";
  }, [title]);
}