import React, { useEffect } from "react";
import { isOrgAccountActive } from "../../api/localstorage_api";
import { useAppContext } from "../../libs/contextLib";
import { usePageTitle } from "../../libs/hooksLib";
import PlaceholderPage from "../layout/PlaceholderPage";

export default function OrgMixedRoute({ children, showLoginNav = true, title = null, ...rest }) {

  const { isAuthenticated } = useAppContext();
  const { setShowLoginNav } = useAppContext();

  usePageTitle(title);
  useEffect(() => {
    setShowLoginNav(showLoginNav);
  });

  if(isAuthenticated && !isOrgAccountActive()){
    return (
      <PlaceholderPage>
        <h1>Sorry! This is an organisation only page, please log in with an organisation account to view.</h1>
      </PlaceholderPage>
    )
  }else{
    return children;
  }
}