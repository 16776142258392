import logger from "../libs/errorLib";

export function isOrgAccountActive(){
    const orgId = localStorage.getItem("activeOrgId");
    return orgId && orgId !== "";
}

export function isOverrideOrgActive(){
    const orgId = localStorage.getItem("overrideOrgId");
    return orgId && orgId !== "";
}

export function getActiveOrgId(){
    const orgId = localStorage.getItem("activeOrgId");
    return orgId;
}

export function setActiveOrgId(orgId){
    logger.debug("Setting orgId in local storage: " + orgId);
    localStorage.setItem("activeOrgId", orgId);
}

export function getOverrideOrgId(){
    const orgId = localStorage.getItem("overrideOrgId");
    return orgId;
}

export function setOverrideOrgId(orgId){
    logger.debug("Setting override orgId in local storage: " + orgId);
    localStorage.setItem("overrideOrgId", orgId);
}

export function removeOverrideOrgId(){
    logger.debug("Removing override orgId in local storage");
    localStorage.removeItem("overrideOrgId");
}

export function removeActiveOrgId(){
    logger.debug("Removing orgId from local storage");
    removeOverrideOrgId();
    localStorage.removeItem("activeOrgId");
}

export function storeAnonView(objectKey){
    const val = localStorage.getItem(objectKey.PK + "#" + objectKey.SK);
    logger.debug(`Testing if item ${objectKey.PK + "#" + objectKey.SK} in local storage, result is: ${(val && val!=="")}`);
    if(val && val !== "") return false;
    localStorage.setItem(objectKey.PK + "#" + objectKey.SK, true);
    return true;
}
