import {useCallback, useEffect, useState} from "react";
import InfoModal from "./InfoModal";
import {InfoContext} from "../../libs/contextLib";
import InfoAlert from "./InfoAlert";
import { useLocation } from "react-router";

export const AlertVariants = {
    ERROR: "danger",
    SUCCESS: "success",
    WARNING: "warning",
    PRIMARY: "primary"
}

export default function InfoManager(props){

    const location = useLocation();
    const [userMessage, setUserMessage] = useState(null);
    const [userMessageModal, setUserMessageModal] = useState(null);
    const [userAlert, setUserAlert] = useState({});

    const resetMessageState = useCallback(() => {
        setUserMessage(null);
        setUserMessageModal(null);
    },[]);

    useEffect(() => {
        setUserAlert({});
        setUserMessage(null);
        setUserMessageModal(null);
    }, [location.pathname]);

    function handleCancel(){
        if(userMessage?.cancelCallback) userMessage.cancelCallback();
        if(userMessageModal?.cancelCallback) userMessageModal.cancelCallback();
        resetMessageState();
    }

    function handleCallback(){
        if(userMessage?.callback) userMessage.callback();
        if(userMessageModal?.callback) userMessageModal.callback();
        resetMessageState();
    }

    return(
        <InfoContext.Provider value={{setUserMessage, setUserAlert, setUserMessageModal}}>
            <InfoAlert className="mt-3" message={userAlert.message} alertDismissedFunction={() => setUserAlert({})} variant={userAlert.variant}/>
            {userMessage && <InfoModal message={userMessage} cancelCallback={handleCancel} acceptCallback={handleCallback}></InfoModal>}
            {userMessageModal && <InfoModal Body={() => userMessageModal.component({...userMessageModal.props, callback: handleCallback, cancelCallback: handleCancel})} cancelCallback={handleCancel} acceptCallback={handleCallback}></InfoModal>}
            {props.children}
        </InfoContext.Provider>
    )
}