import { Container, Image } from "react-bootstrap";
import FormTitle from "../text/FormTitle";

export default function LoadingImage({text}){
    return(
        <Container className="text-center">
            {text && (<FormTitle>{text}</FormTitle>)}
            <Image src="/loading_sm.gif" height="200px" />
        </Container>
    )
}