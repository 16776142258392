import { API } from "aws-amplify";
import { getErrorSerializer, PitchError } from "../libs/shared/exceptions";
import { getModelSerializer } from "../libs/shared/model";
import { isOrgAccountActive } from "./localstorage_api";

export default class APICLient{
    constructor(service){
        this.service = service;
        this.serializer = getModelSerializer();
        this.errorSerializer = getErrorSerializer();
    }
    parseError(e){
        let errorObj;
        if(e.response && e.response.data && e.response.data.serializedError){
            errorObj = this.errorSerializer.deserialize(e.response.data.serializedError);
            throw errorObj;
        }else if(e.response && e.response.data && e.response.data.error){
            throw new PitchError(e.response.data.error, e);
        }else{
            throw new PitchError("Failed to call API", e);
        }
    }
    async get(path, queryString = null){
        try{
            const queryStringParameters = { isorganisation: isOrgAccountActive(), ...queryString };
            const result = await API.get(this.service, path, {queryStringParameters});
            const deserialised = this.serializer.deserialize(result);
            return(deserialised);
        }catch(e){
            this.parseError(e);
        }
    }
    async post(path, body){
        try{
            let result;
            if(body){
                const serializedBody = this.serializer.serialize(body, {anonymiseFirstLevel: true})
                result = await API.post(this.service, path, {body: {...serializedBody, isorganisation: isOrgAccountActive()}});
            }else{
                result = await API.post(this.service, path);
            }
            const deserialised = this.serializer.deserialize(result);
            return(deserialised);
        }catch(e){
            this.parseError(e);
        }
    }
    async put(path, body){
        try{
            let result;
            if(body){
                const serializedBody = this.serializer.serialize(body, {anonymiseFirstLevel: true});
                result = await API.put(this.service, path, {body: {...serializedBody, isorganisation: isOrgAccountActive()}});
            }else{
                result = await API.put(this.service, path);
            }
            const deserialised = this.serializer.deserialize(result);
            return(deserialised);
        }catch(e){
            this.parseError(e);
        }
    }
    async del(path, body, queryString = null){
        try{
            let result;
            const queryStringParameters = { isorganisation: isOrgAccountActive(), ...queryString };
            if(body){
                const serializedBody = this.serializer.serialize(body, {anonymiseFirstLevel: true});
                result = await API.del(this.service, path, {body: serializedBody, queryStringParameters});
            }else{
                result = await API.del(this.service, path, {queryStringParameters});
            }
            const deserialised = this.serializer.deserialize(result);
            return(deserialised);
        }catch(e){
            this.parseError(e);
        }
    }
}