import { useCallback, useState } from "react";
import {components} from "react-select";
import AsyncSelect from "react-select/async";
import { searchForOrganisations } from "../../../api/lambda_api";
import { setOverrideOrgId } from "../../../api/localstorage_api";
import logger from "../../../libs/errorLib";
import { useOrganisation } from "../../../libs/hooksLib";

const Input = ({ ...rest }) => <components.Input {...rest} autoComplete={'nope'} />

export default function OrganisationSelectField({
    initialValue,
    isInvalid,
    className
}){

    const {setOrganisationById} = useOrganisation();

    const defaultOptions = [{
            value: "PROMPT",
            label: 'Search for organisation...'
        }
    ];

    const customStyles = {
        option: (base, state) => ({
            ...base,
            cursor: 'pointer',
        }),
        valueContainer: (base, state) => ({
            ...base,
            paddingLeft: '5px',
            paddingRight: '5px',
            cursor: 'text',
        }),
        control: (base, state) => ({
          ...base,
          width: 300,
          // state.isFocused can display different borderColor if you need it
          borderColor: state.isFocused ?
            '#ddd' : isInvalid ? '#dc3545' : '#ddd',
          // overwrittes hover style
          '&:hover': {
            borderColor: state.isFocused ?
              '#ddd' : isInvalid ? '#dc3545' : '#ddd'
          }
        }),
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999
        })
    }

    const [isLoading, setIsLoading] = useState(false);

    const loadOptions = useCallback(async(value, callback) => {
        setIsLoading(true);
        const searchResults = await searchForOrganisations(value);
        const results = searchResults.items.map((item) => {
            return {
                value: {
                    display: item.organisationName,
                    orgId: item.orgId
                },
                label: item.organisationName
            }
        });
        callback(results);
        setIsLoading(false);
    },[]);

    function handleInputChange(value){
        if(value.value.orgId){
            logger.debug(`Changing org to new ID ${value.value.orgId}`);
            setOrganisationById(value.value.orgId);
            setOverrideOrgId(value.value.orgId);
        }
    }

    return(
        <AsyncSelect className={className} 
            styles={customStyles}
            menuPortalTarget={document.body}
            components={{
                Input
            }}
            cacheOptions
            loadOptions={loadOptions}
            isLoading={isLoading}
            defaultOptions={defaultOptions}
            defaultValue={initialValue}
            placeholder="Search for organisation..."
            onChange={handleInputChange}
        />
    )
}