import {useCallback, useEffect, useState} from "react";
import ErrorModal from "./ErrorModal";
import {ErrorContext} from "../../libs/contextLib";
import logger from "../../libs/errorLib";
import { PitchUserError } from "../../libs/shared/exceptions";

export default function ErrorManager(props){
    
    const [error, setError] = useState(null);
    const [userAckFnc, setUserAckFnc] = useState();

    const resetErrorState = useCallback(() => {
        setError(null);
    },[]);

    useEffect(() => {
        setUserAckFnc(function(){return resetErrorState});
    },[resetErrorState]);

    useEffect(() => {
        if(error && !(error instanceof PitchUserError)){
            logger.error(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error]);

    return(
        <ErrorContext.Provider value={{setError}}>
            <ErrorModal error={error} userAckFnc={userAckFnc}></ErrorModal>
            {props.children}
        </ErrorContext.Provider>
    )
}