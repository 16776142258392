import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {Navbar, Nav, Container, Col, Row, Badge} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import PitchRouter from "./PitchRouter";
import { AppContext } from "./libs/contextLib";
import logger from "./libs/errorLib";
import {authenticateUser, signOutUser} from "./api/auth_api";
import queryString from "query-string";
import "./styles/_text.scss";
import "./styles/forms.scss";
import ErrorBoundary from "./components/error/ErrorBoundary";
import ErrorManager from "./components/error/ErrorManager";
import InfoManager from "./components/info/InfoManager";
import { PitchNoCurrentUserError } from "./libs/shared/exceptions";
import SignInButtons from "./components/buttons/SignInButtons";
import { useOrganisation, usePermissions, useScript, useUser } from "./libs/hooksLib";
import { Organisation, OrganisationUser, Permission } from "./libs/shared/model";
import { getActiveOrgId, getOverrideOrgId, isOrgAccountActive, isOverrideOrgActive, removeActiveOrgId } from "./api/localstorage_api";
import OrganisationSelectField from "./components/forms/fields/OrganisationSelectField";
import { parseOrganisationToPicklistObject } from "./libs/utilities";
import Pitchli from "./components/icons/Pitchli";

function App() {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [showLoginNav, setShowLoginNav] = useState(true);
  const {organisation, setOrganisation, setOrganisationById} = useOrganisation();
  const {user, setUser} = useUser();
  const {loadPermissions, getPermission} = usePermissions();
  const [joiningLink, setJoiningLink] = useState();
  const [qString, setQString] = useState();
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const queryObject = queryString.parse(location.search);
    if(!queryObject.step) queryObject.step = 0;
    let qStr = queryString.stringify(queryObject);
    if(qStr.length > 0) qStr = "?" + qStr;
    setQString(qStr);
  }, [location.search]);

  useScript("https://www.bugherd.com/sidebarv2.js?apikey=oviy2qypexhfjrkh4tjjig", process.env.REACT_APP_STAGE !== "prod");

  useEffect(() => {
    onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoad() {
    try {
      let orgId = null;
      let userOrgId = null;
      if(isOrgAccountActive()){
        userOrgId = getActiveOrgId();
        orgId = isOverrideOrgActive() ? getOverrideOrgId() : userOrgId;
        try{
          await setOrganisationById(orgId);
        }catch(e){
          logger.error(e);
          removeActiveOrgId();
        }
      }
      const user = await authenticateUser(userOrgId);
      setUser(user);
      userHasAuthenticated(true);
    } catch(e) {
      if (!(e instanceof PitchNoCurrentUserError)){
        logger.error(e);
      }
    }
    setIsAuthenticating(false);
  }

  useEffect(() => {
    async function loadPerms(){
      try{
        await loadPermissions(organisation ? organisation.orgId : null);
      }catch(e){
        logger.error(e);
      }
    }
    //TODO update to also allow non Org Users perms loading
    if(user && user instanceof OrganisationUser) loadPerms();
  }, [loadPermissions, organisation, user]);

  async function handleLogout() {
    try{
      await signOutUser();
      logger.debug("User signed out");
      logger.debug("User set to unauthenticated, pushing home route");
    }catch(e){
      logger.error("Error signing out: " + e.message);
    }finally{
      userHasAuthenticated(false);
      navigate("/");
      setUser(null);
      setOrganisation(null);
    }
  }

  return (
    !isAuthenticating && (
      <>
        <Navbar fixed="top" collapseOnSelect expand="md" className="container-white-0 box-shadow-1" bg="white">
          <Container fluid>
            <LinkContainer to="/">
              <Navbar.Brand>
                <Pitchli size={30} className="ms-2 me-0 p-0"/>{'   '}
                <span className="fw-bold text-navy pitchli-text">pitchli</span>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="navbar"/>
            <Navbar.Collapse id="navbar" className="justify-content-end">
              <Nav activeKey={window.location.pathname + qString}>
                {isAuthenticated ? (
                  <>
                    {getOverrideOrgId() && getActiveOrgId() !== getOverrideOrgId() && (
                      <Container><Badge variant="complement">Viewing another account</Badge></Container>
                    )}
                    {organisation && getPermission(Organisation.generateKey(organisation.orgId), Permission.permissionTypes.ADMIN) && (
                      <OrganisationSelectField initialValue={parseOrganisationToPicklistObject(organisation)}/>
                    )}
                    {!organisation && (
                      <LinkContainer to="/settings">
                        <Nav.Link className="text-navy">Settings</Nav.Link>
                      </LinkContainer>
                    )}
                    {organisation && (
                      <LinkContainer to="/org_settings">
                        <Nav.Link className="text-navy">Settings</Nav.Link>
                      </LinkContainer>
                    )}
                    <Nav.Link onClick={handleLogout} className="text-navy">Logout</Nav.Link>
                  </>
                ) : (
                    showLoginNav && (
                      <>
                        <LinkContainer to="/pricing" className="mt-2 mx-2">
                          <Nav.Link className="text-navy">Pricing</Nav.Link>
                        </LinkContainer>
                        <SignInButtons linkClassName="m-2"/>
                      </>
                    )
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div className="full-height-container px-0 mx-auto pt-4">
          <ErrorBoundary>
            <ErrorManager>
              <InfoManager>
                <AppContext.Provider value={{ isAuthenticated, isAuthenticating, userHasAuthenticated, joiningLink, setJoiningLink, setShowLoginNav }}>
                  <PitchRouter/>
                </AppContext.Provider>
              </InfoManager>
            </ErrorManager>
          </ErrorBoundary>
        </div>
        <Container className="container-navy full-width-container justify-content-center">
          <Container xs={12} className="p-3">
            <Row className="px-3">
              <Col sm={4}>
                <ul className="list-style-none">
                  <li className="py-1"><span className="text-fineprint">&copy; Pitchli 2021</span></li>
                </ul>
              </Col>
              <Col sm={4}>
                <ul className="list-style-none">
                  <li className="py-1"><a href="mailto:support@pitchli.com?subject=Pitchli%20help%20please" className="text-fineprint link-white">Contact support</a></li>
                </ul>
              </Col>
              <Col sm={4}>
                <ul className="list-style-none">
                  <li className="py-1"><a href="/legal" className="text-fineprint link-white">Terms &amp; conditions</a></li>
                  <li className="py-1"><a href="/privacy" className="text-fineprint link-white">Privacy policy</a></li>
                </ul>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    )
  );
}

export default App;