import {Auth} from "aws-amplify";
import {CognitoIdentityProvider} from '@aws-sdk/client-cognito-identity-provider';
import {createOrganisation, createOrganisationUserFromLink, deleteUser, getOrganisation, getUser, updateUser} from "./lambda_api";
import { PitchError, PitchNoCurrentUserError, PitchNotOrganisationUserError, PitchUserError, PitchUserNotConfirmedError } from "../libs/shared/exceptions";
import {appConfig} from "../libs/shared/app-config";
import logger from "../libs/errorLib";
import { checkNullParameters } from "../libs/utilities";
import { User } from "../libs/shared/model";
import { getActiveOrgId, removeActiveOrgId, setActiveOrgId } from "./localstorage_api";

export async function authenticateUser(orgId = null){
    try{
        logger.debug("Authenticating user, org ID: " + orgId);
        await Auth.currentSession();
    }catch(e){
        if (e !== 'No current user') {
            throw new PitchError("Failed to get users current session: " + e.message, e);
        }else{
            throw new PitchNoCurrentUserError("Failed to get users current session: " + e.message, e);
        }
    }
    let user;
    try{
        user = await getUserAndCheckIdentityId(orgId);
        return user;
    }catch(e){
        logger.error("User has session, but can't load account: " + e.message);
        await Auth.signOut();
        throw new PitchNoCurrentUserError("User has session, but can't load account: " + e.message, e);
    }
}

/**
 * Signs user in with Amplify and handles various Amplify errors
 * @param {string} email 
 * @param {string} password 
 */
export async function signInUser(email, password){
    try{
        return await Auth.signIn(email, password);
    }catch(e){
        if(e.code && e.code === "UserNotConfirmedException"){
            throw new PitchUserNotConfirmedError(e.message, e);
        }else if(e.code && (e.code === "NotAuthorizedException" || e.code === "UserNotFoundException" || e.code === "TooManyRequestsException" || e.code === "UsernameExistsException" || e.code === "CodeDeliveryFailureException")){
            throw new PitchUserError(e.message, e);
        }else{
            throw new PitchError(e.message, e);
        }
    }
}

export async function getCognitoOrgId(){
    const awsUserInfo = await Auth.currentUserInfo();
    if(!awsUserInfo) return null;
    const orgId = awsUserInfo.attributes['custom:organisationId'];
    return orgId;
}

async function setCognitoOrgId(orgId){
    const cognitoUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(cognitoUser, {
        'custom:organisationId': orgId
    });
}

export async function getSignedInUser(isOrganisation = false){
    try{
        logger.debug("Getting signed in user, is organisation user: " + isOrganisation);
        let user;
        if(isOrganisation){
            //Check local storage to see if there's a current org id
            let orgId = getActiveOrgId();
            //If none, get the orgId from the cognito user account and then set in local storage
            if(!orgId) {
                orgId = await getCognitoOrgId();
                setActiveOrgId(orgId);
            }
            if(!orgId){
                //If there's still no org ID, then this isn't an organisation user. Throw specific error to be handled
                throw new PitchNotOrganisationUserError("Not an organisation user");
            }
            try{
                user = await getUserAndCheckIdentityId(orgId);
            }catch(e){
                throw new PitchError(e.message, e);
            }
            logger.debug("Got signed in user, org ID: " + orgId);
        }else{
            user = await getUserAndCheckIdentityId();
            logger.debug("User object is instance type: " + user.constructor.name);
        }
        user = checkUserIdentityId(user);
        return user;
    }catch(e){
        if(e instanceof PitchNotOrganisationUserError) throw e;
        throw new PitchError(e.message, e);
    }
}

async function getUserAndCheckIdentityId(orgId = null){
    let user = await getUser(orgId);
    user = checkUserIdentityId(user);
    return user;
}

export async function checkUserIdentityId(user){
    if(!user.identityId){
        logger.debug("Updating user record with identity id");
        const awsUserCredentials = await Auth.currentUserCredentials();
        // Update user with identity pool id so that S3 locations can be identified
        user.identityId = awsUserCredentials.identityId;
        user = await updateUser(user);
    }
    return user;
}

export async function signOutUser(){
    try{
        removeActiveOrgId();
        logger.debug("Signing out user via Auth");
        await Auth.signOut({global: true});
    }catch(e){
        throw new PitchError("Failed to sign out user: " + e.message, e);
    }
}

export async function confirmUserSignUp(email, confirmCode){
    checkNullParameters("confirmUserSignUp", email, confirmCode);
    try{
        await Auth.confirmSignUp(email, confirmCode);
    }catch(e){
        if(e.code && (e.code === "AliasExistsException" || e.code === "CodeMismatchException" || e.code === "ExpiredCodeException" || e.code ==="LimitExceededException" || e.code === "TooManyFailedAttemptsException")){
            throw new PitchUserError(e.message, e);
        }else{
            throw new PitchError(e.message, e);
        }
    }
}

export async function resendConfirmationCode(email){
    try{
        await Auth.resendSignUp(email);
    }catch(e){
        if(e.code && (e.code === "AliasExistsException" || e.code === "CodeMismatchException" || e.code === "ExpiredCodeException" || e.code ==="LimitExceededException" || e.code === "TooManyFailedAttemptsException")){
            throw new PitchUserError(e.message, e);
        }else{
            throw new PitchError(e.message, e);
        }
    }
}

export async function forgotPassword(email){
    try{
        await Auth.forgotPassword(email);
    }catch(e){
        if(e.code && (e.code === "LimitExceededException" || e.code === "UserNotFoundException" || e.code === "InvalidParameterException")){
            throw new PitchUserError("Too many requests made: " + e.message, e);
          }else{
            throw new PitchError(e.message, e);
          }
    }
}

export async function forgotPasswordConfirm(email, password, code){
    try{
        await Auth.forgotPasswordSubmit(email, code, password);
    }catch(e){
        throw new PitchError(e.message, e);
    }
}

export async function completePasswordChange(cognitoUser, newPassword){
    try{
        await Auth.completeNewPassword(cognitoUser, newPassword);
    }catch(e){
        throw new PitchError(e.message, e);
    }
}

export async function createOrganisationAndUser(email, joiningLink = null){
    try{
        let org;
        let user;
        if(joiningLink){
            user = await createOrganisationUserFromLink(joiningLink.orgId, joiningLink.linkId, email);
            org = await getOrganisation(joiningLink.orgId);
        }else{
            org = await createOrganisation(email);
            user = await getUser(org.orgId);
        }
        logger.debug("Created org user, org ID: " + org.orgId);
        setActiveOrgId(org.orgId);
        await setCognitoOrgId(org.orgId);
        return {org, user};
    }catch(e){
        throw new PitchError(e.message, e);
    }
}

export async function deleteUserAccount(user){
    try{
        const cognitoUser = await Auth.currentAuthenticatedUser({bypassCache: true});
        // Create a new CognitoIdentityProvider object for your
        // Cognito User Pool Region
        const cognitoIdentityProvider = new CognitoIdentityProvider({region: appConfig.cognito.REGION});
        // Create the required request parameter
        var params = {
            AccessToken: cognitoUser.signInUserSession.accessToken.jwtToken
        };
        // Call the deleteUser function using a callback function
        await cognitoIdentityProvider.deleteUser(params);
        await deleteUser(user);
        await signOutUser();
    }catch(e){
        throw new PitchError("Failed to delete user account: " + e.message, e);
    }
}

export async function completeAccountCreation(email, password, confirmationCode, isOrganisation = false, joiningLink){
    // Cognito completion
    await confirmUserSignUp(email, confirmationCode);
    await signInUser(email, password);
    // Get user object that has been created, and update status.
    let user = await getSignedInUser();
    user.status = User.userRegistrationStatus.CONFIRMED;
    user = await updateUser(user);

    // If organisation, create separate organisation user and return it
    if(isOrganisation){
        const result = await createOrganisationAndUser(email, joiningLink);
        user = result.user;
    }
    return user;
}