import {Container, Row, Col} from "react-bootstrap";
import { useDimensions } from "../../libs/hooksLib";

const sizing = {
    small: {
        xs: {
            span:12,
            offset: 0
        },
        sm: {
            span: 11,
            offset:0.5,
        },
        md: {
            span: 8,
            offset: 2
        },
        lg: {
            span: 6,
            offset: 3
        },
        xl: {
            span: 6,
            offset: 3
        }
    },
    large: {
        xs: {
            span:12,
            offset: 0
        },
        sm: {
            span: 12,
            offset:0,
        },
        md: {
            span: 12,
            offset: 0
        },
        lg: {
            span: 12,
            offset: 0
        },
        xl: {
            span: 10,
            offset: 1
        }
    }
}

function getSizing(panelSize, bootstrapSize){
    return sizing[panelSize][bootstrapSize];
}

export default function PanelContainer({
    className = "",
    size = "large",
    ...props
  }){

    const {window} = useDimensions();

    return(
        <Container>
            <Row>
                <Col xl={getSizing(size, "xl")}  lg={getSizing(size, "lg")} md={getSizing(size, "md")} sm={getSizing(size, "sm")} xs={getSizing(size, "xs")} className={(window.bootstrapSize !== "xs") ? `container-white-0 box-shadow-2 p-4 ${className}` : `container-white-0 ${className}`}>
                    {props.children}
                </Col>
            </Row>
        </Container>
    );
}