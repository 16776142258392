import {logError, logMessage} from "./sentryLib";
import {logLevel} from "./shared/enums";

const logger = {
  debug: (message) => debug(message),
  info: (message) => info(message),
  warn: (message) => warn(message),
  error: (error) => onError(error)
}

function onError(error) {
  let errorInfo = {};
  if(!error){
    error = new Error('Unknown error has occurred, error object not passed to error library.');
  }
  let message = error.toString();

  // Auth errors
  if (!(error instanceof Error) && error.message) {
    errorInfo = error;
    message = error.message;
    error = new Error(message);
    // API errors
  } else if (error.config && error.config.url) {
    errorInfo.url = error.config.url;
  }

  logError(error, errorInfo);
};

function debug(message){
  logMessage(message, logLevel.DEBUG);
}

function info(message){
  logMessage(message, logLevel.INFO);
}

function warn(message){
  logMessage(message, logLevel.WARN);
}

export default logger;