import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../../libs/contextLib";
import queryString from "query-string";
import { usePageTitle } from "../../libs/hooksLib";

export default function UnauthenticatedRoute({ children, showLoginNav = true, title = null, ...rest }) {
  const { isAuthenticated, setShowLoginNav } = useAppContext();
  const location = useLocation();
  usePageTitle(title);
  const redirect = queryString.parse(location.search).redirect;

  useEffect(() => {
    setShowLoginNav(showLoginNav);
  }, [setShowLoginNav, showLoginNav]);

  if(!isAuthenticated){
    return children;
  }else{
    return <Navigate replace to={redirect === "" || redirect === null || redirect === undefined ? "/" : redirect} />;
  }
}