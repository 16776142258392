import { Nav } from "react-bootstrap";
import { useLocation } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import PitchButton from "./PitchButton";

export default function SignInButtons({signInText = "Sign in", signUpText = "Try for free", linkClassName = "m-2"}){

    const location = useLocation();

    return(
        <div className="navbar-nav">
            {signInText && (<LinkContainer to={"/login_choice" + location.search} className={linkClassName}>
                <Nav.Link as={PitchButton} variant="outline-complement">{signInText}</Nav.Link>
            </LinkContainer>)}
            {signUpText && (<LinkContainer to={"/org_signup" + location.search} className={linkClassName}>
                <Nav.Link as={PitchButton} variant="primary">{signUpText}</Nav.Link>
            </LinkContainer>)}
        </div>
    );
}