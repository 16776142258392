import { useContext, createContext} from "react";

export const AppContext = createContext(null);
export const ErrorContext = createContext(null);
export const InfoContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export function useErrorContext(){
  return useContext(ErrorContext);
}

export function useInfoContext(){
  return useContext(InfoContext);
}