import { Col, Row } from "react-bootstrap";

export default function VerticalCenterContainer(props){
    return(
        <Row className="full-height-container pb-5">
			<Col className="my-auto">
				{props.children}
			</Col>
		</Row>
    );
}