import APIClient from "./api_client";
import { checkNullParameters } from "../libs/utilities";
import urlencode from "urlencode";
import { Interaction, Link, OrganisationUser, User } from "../libs/shared/model";
import { PitchError } from "../libs/shared/exceptions";
import { checkUserIdentityId } from "./auth_api";

const client = new APIClient("lambda");

export function getUser(orgId = null){
    let user;
    if(orgId){
        user = client.get(`/organisations/${orgId}/user`);
    }else{
        user = client.get(`/user`);
    }
    return user;
}

export function getUsers(orgId, startKey = null){
    checkNullParameters("updateUser", orgId);
    let path = `/organisations/${orgId}/users?pageSize=10`;
    if(startKey) path += `&startKey=${urlencode(JSON.stringify(startKey))}`
    return client.get(path);
}

/**
 * Sends user update to server and gets back update
 * @param {User} user 
 * @param {boolean} updateApplications whether to also iterate through any applications and update details
 * @returns User
 */
export function updateUser(user, updateApplications = false){
    checkNullParameters("updateUser", user);
    if(user instanceof OrganisationUser){
        return client.put(`/organisations/${user.orgId}/user`, {
            user,
            updateApplications: updateApplications
        });
    }else if(user instanceof User){
        return client.put("/user", {
            user,
            updateApplications: updateApplications
        });
    }else{
        throw new PitchError("Update is not a user object");
    }
}

/**
 * Completes user signup, also updates user update
 * @param {User} user
 * @returns user
 */
export async function completeUserSignup(user){
    if(user instanceof OrganisationUser){
        user = await client.put(`/organisations/${user.orgId}/user/complete`, { user });
    }else{
        user = await client.put("/user/complete", { user });
    }
    user = checkUserIdentityId(user);
    return user;
}

/**
 * Completes organisation sign up. Also updates organisation object.
 * @param {Organisation} org 
 * @returns Organisation
 */
export function completeOrganisationSignup(org){
    checkNullParameters("CompleteOrganisationSignUp", org);
    return client.put(`/organisations/${org.orgId}/complete`, {org});
}

export function deleteUser(user){
    return client.del("/user", {user});
}

/**
 * Creates new job and returns it
 * @param {string} orgId 
 * @returns Job
 */
export function createJob(orgId){
    checkNullParameters("createJob", orgId);
    return client.post(`/organisations/${orgId}/jobs`);
}

/**
 * Gets a job
 * @param {string} orgId 
 * @param {string} jobId 
 * @returns Job
 */
export function getJob(orgId, jobId){
    checkNullParameters("getJob", orgId, jobId);
    return client.get(`/organisations/${orgId}/jobs/${jobId}`);
}

export function updateJob(job){
    checkNullParameters("updateJob", job);
    return client.put(`/organisations/${job.orgId}/jobs/${job.jobId}`, {job});
}

export function deleteJob(job){
    checkNullParameters("deleteJob", job);
    return client.del(`/organisations/${job.orgId}/jobs/${job.jobId}`, {job});
}

export function loadLink(id){
    checkNullParameters("loadLink", id);
    return client.get(`/links/${id}`);
}

export function deleteLink(link){
    checkNullParameters("deleteLink", link);
    return client.del(`/links/${link.linkId}`, {link});
}

export function getOrCreateInvite(linkId) {
    checkNullParameters("getOrCreateInvite", linkId);
    return client.post(`/links/${linkId}/convert`, {linkType: Link.linkTypes.INVITE});
}

export function getOrCreateSharedJob(orgId, linkId) {
    checkNullParameters("getOrCreateSharedJob", orgId, linkId);
    return client.post(`/links/${linkId}/convert`, {orgId, linkType: Link.linkTypes.APPLICANT_LIST});
}

export function loadInvite(id) {
    checkNullParameters("loadInvite", id);
    return client.get(`/user/invites/${id}`);
}

export function loadApplication(orgId, jobId, userId, inviteId){
    checkNullParameters("loadApplication", orgId, jobId, userId, inviteId);
    return client.get(`/organisations/${orgId}/jobs/${jobId}/users/${userId}/invites/${inviteId}`)
}

export function loadInvites(startDate, endDate, startKey = null) {
    let path = `/user/invites?`;
    if(startDate) path += `startDate=${urlencode(JSON.stringify(startDate))}`
    if(endDate) path += `endDate=${urlencode(JSON.stringify(endDate))}`
    if(startKey) path += `startKey=${urlencode(JSON.stringify(startKey))}`
    return client.get(path, {ascendingOrder: false});
}

export function updateInvite(invite){
    checkNullParameters("updateInvite", invite);
    return client.put(`/invites/${invite.inviteId}`, {invite});
}

export function createPitchForInvite(invite, pitch) {
    checkNullParameters("createPitch", invite, pitch);
    return client.post(`/invites/${invite.inviteId}/pitches`, { invite, pitch });
  }

export function createPitchForJob(job, pitch) {
    checkNullParameters("createPitch", job, pitch);
    return client.post(`/organisations/${job.orgId}/jobs/${job.jobId}/pitches`,{ job, pitch });
}

export function deletePitchFromInvite(invite, pitch){
    checkNullParameters("deletePitchFromInvite", invite, pitch);
    return client.del(`/invites/${invite.inviteId}/pitches/${pitch.pitchId}`, {invite, pitch});
}

export function updatePitchForJob(job, pitch){
    checkNullParameters("deletePitchFromApp", job, pitch);
    return client.put(`/organisations/${job.orgId}/jobs/${job.jobId}/pitches/${pitch.pitchId}`, {job, pitch});
}

export function deletePitchFromJob(job, pitch){
    checkNullParameters("deletePitchFromJob", job, pitch);
    return client.del(`/organisations/${job.orgId}/jobs/${job.jobId}/pitches/${pitch.pitchId}`, {job, pitch});
}

export function getInvitesForJobByDecisionStatus(orgId, jobId, decisionStatus, startKey = null, download = false){
    checkNullParameters("getInvitesForJobByDecisionStatus", orgId, jobId, decisionStatus);
    return client.get(`/organisations/${orgId}/jobs/${jobId}/applications`, {decisionStatus, startKey, pageSize: 5, download});
}

export function createApplicationForInvite(invite){
    checkNullParameters("createApplicationForInvite", invite);
    return client.post(`/jobs/${invite.jobId}/applications`, { invite });
}

export function submitApplication(invite){
    checkNullParameters("submitApplication", invite);
    return client.post(`/jobs/${invite.jobId}/applications/${invite.application.appId}/submit`, { invite });
}

export function loadJob(orgId, jobId) {
    checkNullParameters("loadJob", orgId, jobId);
    return client.get(`/organisations/${orgId}/jobs/${jobId}`);
}

export function loadJobs(orgId, startDate, endDate, startKey = null) {
    checkNullParameters("loadJobs", orgId);
    let path = `/organisations/${orgId}/jobs/?`;
    if(startDate) path += `startDate=${urlencode(JSON.stringify(startDate))}`
    if(endDate) path += `endDate=${urlencode(JSON.stringify(endDate))}`
    if(startKey) path += `startKey=${urlencode(JSON.stringify(startKey))}`
    return client.get(path, {ascendingOrder: false});
}

export function loadSharedJobs(orgId, startKey = null) {
    checkNullParameters("loadSharedJobs", orgId);
    let path = `/organisations/sharedjobs/${orgId}?`;
    if(startKey) path += `startKey=${urlencode(JSON.stringify(startKey))}`
    return client.get(path, {ascendingOrder: false});
}

export function createOrganisation(primaryUserEmailAddress){
    checkNullParameters("createOrganisation", primaryUserEmailAddress);
    return client.post("/organisations", {primaryUserEmailAddress});
}

export function getOrganisation(id){
    checkNullParameters("getOrganisation", id);
    return client.get(`/organisations/${id}`);
}

export function searchForOrganisations(searchTerm, startKey = null){
    checkNullParameters("searchForOrganisations", searchTerm);
    return client.get(`/organisations/`, {searchTerm, startKey});
}

export function updateOrganisation(organisation){
    checkNullParameters("updateOrganisation", organisation);
    return client.put(`/organisations/${organisation.orgId}`,{ organisation });
}

export function createOrganisationUser(orgId, emailAddress){
    checkNullParameters("createOrganisationUser", orgId, emailAddress);
    return client.post(`/organisations/${orgId}/user`, {emailAddress});
}

export function createOrganisationUserFromLink(orgId, linkId, emailAddress){
    checkNullParameters("createOrganisationUserFromLink", orgId, linkId, emailAddress);
    return client.post(`/organisations/${orgId}/user/link`, {linkId, emailAddress});
}

export function getUserPermissions(orgId, userId, wildCardOnly = true){
    checkNullParameters("getUserPermissions", orgId);
    if(userId) return client.get(`/organisations/${orgId}/user/${userId}/permissions`, {wildCardOnly});
    if(orgId) return client.get(`/organisations/${orgId}/user/permissions`, {wildCardOnly});
    return client.get(`/user/permissions`, {wildCardOnly});
}

export function updateUserPermissions(orgId, userId, permissionsToCreate, permissionsToUpdate, permissionsToDelete){
    checkNullParameters("updateUserPermissions", orgId, userId);
    const args = {};
    let update = false;
    if(permissionsToCreate.length > 0) { args.create = permissionsToCreate; update = true; }
    if(permissionsToUpdate.length > 0) { args.update = permissionsToUpdate; update = true; }
    if(permissionsToDelete.length > 0) { args.delete = permissionsToDelete; update = true; }
    if(update) return client.put(`/organisations/${orgId}/user/${userId}/permissions`, {...args});
}

export function getStripePricing(frequency){
    return client.get(`/pricing`, {frequency});
}

export function createStripeSession(user, priceId, completionPath){
    checkNullParameters("createStripeSession", user, priceId, completionPath);
    return client.post(`/subscriptions`, {user, priceId, completionPath})
}

export function createStripePortalSession(stripeCustomerId, completionPath){
    checkNullParameters("createStripePortalSession", stripeCustomerId, completionPath);
    return client.post(`/subscriptions/manage`, {stripeCustomerId, completionPath})
}

export function confirmJob(userId, orgId, jobId){
    checkNullParameters("postJob", orgId, jobId, userId);
    return client.put(`/organisations/${orgId}/jobs/${jobId}/confirm`, { userId, orgId, jobId });
}

export function getInviteSharingLink(job){
    checkNullParameters("getInviteSharingLink", job);
    return client.post(`/links`, {job, linkType: Link.linkTypes.INVITE});
}

export function getJobSharingLink(job){
    checkNullParameters("getJobSharingLink", job);
    return client.post(`/links`, {job, linkType: Link.linkTypes.APPLICANT_LIST});
}

export function getJoinAccountSharingLinks(orgId, startKey){
    checkNullParameters("getJoinAccountSharingLinks", orgId);
    return client.get(`/links/join/${orgId}`, {startKey});
}

export function validateEmailAgainstCheckKey(linkId, emailAddress){
    checkNullParameters("getJoinAccountSharingLink", linkId);
    return client.post(`/links/validate`, {linkId, linkType: Link.linkTypes.JOIN_ACCOUNT, emailAddress});
}

export function inviteCandidateToJob(job, firstName, emailAddress){
    checkNullParameters("inviteCandidateToJob", job, firstName, emailAddress);
    return client.post(`/links/invite`, {job, firstName, emailAddress, linkType: Link.linkTypes.INVITE});
}

export function inviteUserToAccount(org, firstName, emailAddress, permissions){
    checkNullParameters("inviteUserToAccount", org, firstName, emailAddress, permissions);
    return client.post(`/links/invite`, {org, firstName, emailAddress, permissions, linkType: Link.linkTypes.JOIN_ACCOUNT});
}

export function inviteOtherOrganisationToJob(job, firstName, emailAddress){
    checkNullParameters("inviteOtherOrganisationToJob", job, firstName, emailAddress);
    return client.post(`/links/invite`, {job, firstName, emailAddress, linkType: Link.linkTypes.APPLICANT_LIST});
}

export function searchPostcode(searchTerm){
    checkNullParameters("searchPostcode", searchTerm);
    if(searchTerm.length < 4) return [];
    return client.get(`/user/postcode/${urlencode(searchTerm)}`);
}
export function logView(user, itemKey, metrics, viewerOrgId = null){
    checkNullParameters("logView", user, itemKey, metrics);
    if(viewerOrgId) return client.post(`/organisations/${viewerOrgId}/user/interaction`, { user, interactionType: Interaction.interactionTypes.VIEW, metrics, itemKey });
    return client.post(`/user/interaction`, { user, interactionType: Interaction.interactionTypes.VIEW, metrics, itemKey });
}
export function updateInteraction(interaction, viewerOrgId = null){
    checkNullParameters("updateInteraction", interaction);
    if(viewerOrgId) return client.put(`/organisations/${viewerOrgId}/user/interaction/${interaction.interactionId}`, { interaction });
    return client.put(`/user/interaction/${interaction.interactionId}`, { interaction });
}
export function logAnonView(itemKey, metrics, firstView = true){
    checkNullParameters("logView", itemKey, metrics);
    return client.post(`/user/anon/interaction`, { interactionType: Interaction.interactionTypes.VIEW, metrics, itemKey, firstView });
}
export function logAnonMetrics(itemKey, metrics){
    checkNullParameters("logView", itemKey, metrics);
    return client.put(`/user/anon/interaction`, { interactionType: Interaction.interactionTypes.VIEW, metrics, itemKey });
}
export function logDecision(viewerOrgId, user, itemKey, decision){
    checkNullParameters("logDecision", viewerOrgId, user, itemKey);
    return client.post(`/organisations/${viewerOrgId}/user/interaction`, {user, interactionType: Interaction.interactionTypes.DECISION, decision, itemKey});
}
export function logRating(viewerOrgId, user, itemKey, rating){
    checkNullParameters("logRating", viewerOrgId, user, itemKey, rating);
    return client.post(`/organisations/${viewerOrgId}/user/interaction`, {user, interactionType: Interaction.interactionTypes.RATING, rating, itemKey});
}
export function logComment(viewerOrgId, user, itemKey, comment){
    checkNullParameters("logComment", viewerOrgId, user, itemKey, comment);
    return client.post(`/organisations/${viewerOrgId}/user/interaction`, {user, interactionType: Interaction.interactionTypes.COMMENT, comment, itemKey});
}
export function getInteractionsForInvite(inviteId){
    checkNullParameters("getInteractionsForInvite", inviteId);
    return client.get(`/invites/${inviteId}/interactions`);
}
export function getInteractionsForApplication(orgId, jobId, userId, inviteId, interactionType = null, startKey = null, pageSize = null){
    checkNullParameters("getInteractionsForApplication", orgId, jobId, userId, inviteId);
    return client.get(`/organisations/${orgId}/jobs/${jobId}/users/${userId}/invites/${inviteId}/interactions`, {interactionType, startKey: JSON.stringify(startKey), pageSize});
}
export function getInteractionsForJob(orgId, jobId){
    checkNullParameters("getInteractionsForJob", orgId, jobId);
    return client.get(`/organisations/${orgId}/jobs/${jobId}/interactions`);
}
export function getFilePreview(key, recordCount, headerRow){
    checkNullParameters("getFilePreview", key);
    return client.put(`/data/file/preview`, {key, recordCount, headerRow});
}
export function inviteFileToJob(job, Key, headerRow, firstNameField, emailField){
    checkNullParameters("inviteFileToJob", job, Key, firstNameField, emailField);
    return client.post(`/data/file/invite`, {job, Key, headerRow, firstNameField, emailField});
}