export const standardStyles = {
    UNOPENED: "secondary",
    OPENED: "primary",
    SUBMITTED: "success",
    NEW: "primary",
    SHORTLISTED: "success",
    REJECTED: "primary",
    PRIME: "primary",
    SECONDARY1: "complement",
    SECONDARY2: "secondary2",
    COMPLEMENT: "success"
};

export const limitTypes = {
    DURATION: 0,
    APPLICATIONS: 1
};

export const durationTypes = {
    SECONDS: 0,
    MINUTES: 1,
    HOURS: 2,
    DAYS: 3,
    WEEKS: 4,
    MONTHS: 5,
    QUARTERS: 6,
    YEARS: 7
};

export const durationTypeNames = {
    0: "seconds",
    1: "minutes",
    2: "hours",
    3: "days",
    4: "weeks",
    5: "months",
    6: "quarters",
    7: "years"
};

export const itemTypes = {
    APPLICATION: "APP",
    INVITE: "INVITE",
    JOB: "JOB",
    PITCH: "PITCH",
    ORGANISATION: "ORG",
    USER: "USER",
    LINK: "LINKTYPE",
    METADATA: "METADATA",
    OFFER: "OFFER",
    TRANSACTION: "TRANSACTION",
    PRICING: "PRICING"
}

export const logLevel = {
    DEBUG: 0,
    INFO: 1,
    WARN: 2,
    ERROR: 3
};

export const statusFilterTypes = {
    ALL: 0,
    ACTIVE: 1,
    CLOSED: 2,
    NEW: 3,
    SHORTLISTED: 4,
    REJECTED: 5
};

export const viewTypes = {
    LIST: 0,
    FEED: 1
};

export const statusFilterTypeNames = {
    0: {
        text: "All"
    },
    1: {
        text: "Active"
    },
    2: {
        text: "Closed"
    },
    3: {
        text: "New"
    },
    4: {
        text: "Shortlisted"
    },
    5: {
        text: "Rejected"
    }
};

export const viewTypeNames = {
    0: "List",
    1: "Feed"
};

export const subscriptionTypes = {
    MASTER: "_master",
    CONFIRMATIONS: 0,
    VIEWS: 1,
    LIKES: 2,
    DECISIONS: 3,
    APPLICATION_SUBMISSIONS: 4
}